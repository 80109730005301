import React, {useEffect, useState} from 'react';
import Loading from "components/Loading";
import useAPI from "hooks/API";
import {Button, Card, Switch} from "antd";
import {setSuccess} from "../../reducers/message";
import {useAppDispatch} from "../../store";

export const ConfigsPage = () => {
  const [configs, setConfigs] = useState<{ [k: string]: string }>();
  const [adsEnabled, setAdsEnabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [loadingRefreshHistory, setLoadingRefreshHistory] = useState(false);
  const [adsInfo, setAdsInfo] = useState({adUrl: '', landingUrl: ''})
  const [adUrl, setAdUrl] = useState('');
  const [landingUrl, setLandingUrl] = useState('');
  const dispatch = useAppDispatch();

  const api = useAPI();

  const getConfigs = () => api.get("/admin/configs").then(res => {
    let disabled = String(true) == res.result.ads_disabled
    setAdsEnabled(!disabled);
    setConfigs(res.result);
  });
  const getAdsInfo = () => api.get("/admin/ads-info").then(res => {
    setAdsInfo(res.result)
  });
  useEffect(() => {
    getConfigs();
    getAdsInfo();
  }, []);

  if (!configs || loading) {
    return <Loading/>;
  }

  const handleFinish = (key: string, value: any) => {
    api.post(`/admin/configs/${key}`, value).then(getConfigs);
  }


  const updateAdsInfo = (value: any) => api.post("/admin/ads-info", value).then(res => {
    setAdsInfo(res.result)
  })

  const handleChangeAdUrl = (event: any) => {
    setAdUrl(event.target.value)
  }

  const handleChangeLandingUrl = (event: any) => {
    setLandingUrl(event.target.value)
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    updateAdsInfo({adUrl: adUrl, landingUrl: landingUrl})
    setAdUrl('');
    setLandingUrl('');
  }

  const handleRefresh = () => {
    setLoadingRefresh(true);
    api.post("/admin/refresh/parities")
      .then(() => dispatch(setSuccess("Refreshed parity cache")))
      .then(() => api.post("/admin/refresh/assets"))
      .then(() => dispatch(setSuccess("Refreshed asset cache")))
      .finally(() => {
        setLoadingRefresh(false);
      })
  }

  const handleRefreshHistory = () => {
    setLoadingRefreshHistory(true);
    api.post("/admin/refresh/history")
      .then(() => dispatch(setSuccess("Refreshed history cache")))
      .finally(() => {
        setLoadingRefreshHistory(false);
      })
  }

  return (
    <div style={{display: 'flex', justifyContent: 'space-around'}}>
      <Card title="Mobile Ads" style={{width: 500}}>
        <div>
          <h3>Ads</h3>
          <label>
            <span>{`Ads ${adsEnabled ? 'Enabled' : 'Disabled'}`}</span>
            <Switch
              onChange={checked => handleFinish("ads_disabled", !checked)}
              checked={adsEnabled}
              className="react-switch"
              style={{marginLeft: "45px", marginBottom: "10px"}}
            />
          </label>

        </div>
        <br/>
        <div>
          <h3>Current Ads Url Info</h3>
          <label>Ad Url:
            <input
              type="text"
              name="ad_url"
              value={adsInfo.adUrl || ""}
              disabled
            />
          </label>
          <br/>
          <label>Landing Url:
            <input
              type="text"
              name="landing_url"
              value={adsInfo.landingUrl || ""}
              disabled
            />
          </label><br/>
        </div>
        <br/>
        <div>
          <h3>Update Ads Url Info</h3>
          <form onSubmit={handleSubmit}>
            <label>Ad Url:
              <input
                type="text"
                name="ad_url"
                value={adUrl || ""}
                onChange={handleChangeAdUrl}
              />
            </label><br/>
            <label>Landing Url:
              <input
                type="text"
                name="landing_url"
                value={landingUrl || ""}
                onChange={handleChangeLandingUrl}
              />
            </label><br/>
            <input type="submit" value="Update"/>
          </form>
        </div>
      </Card>
      <Card title={"Cache config"} style={{width: 500}}>
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-around", width: "100%"}}>
          <Button type={"primary"} onClick={handleRefresh} loading={loadingRefresh}>Refresh Assets</Button>
          <Button type={"primary"} onClick={handleRefreshHistory} loading={loadingRefreshHistory}>Refresh Asset Rate History</Button>
        </div>
      </Card>
    </div>
  );
}

export default ConfigsPage;
