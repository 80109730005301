import React, {useEffect, useState} from 'react';
import useAPI from "hooks/API";
import {useParams} from "react-router-dom";
import {Card, Select, Tag} from "antd";
import Loading from "components/Loading";
import {QSParams} from "services/ApiService";

const styles = {
  twoCols: {
    display: "flex",
    justifyContent: "space-between"
  }
}

const formatter = new Intl.NumberFormat('tr-TR', {
  style: 'currency',
  currency: 'TRY',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const UserAppViewPage = () => {
  const {userId, deviceId} = useParams<{ userId: string, deviceId: string }>();
  const api = useAPI();
  const [token, setToken] = useState<string>("");
  const [portfolios, setPortfolios] = useState<any>([]);
  const [user, setUser] = useState<any>(null);
  const [data, setData] = useState<any>();
  const [historyParams, setHistoryParams] = useState<any>({});
  const [history, setHistory] = useState<any>();
  const [historyPL, setHistoryPL] = useState<any>();
  const pfId = user ? user.defaultPortfolioId : null;

  useEffect(() => {
    api.get(`/admin/devices/${deviceId}/token`).then(res => setToken(res.message))
    api.get(`/admin/users/${userId}`).then(res => setUser(res.result));
  }, []);

  useEffect(() => {
    if (token && pfId) {
      api.get("/v2/account/assets", {}, token).then(res => setData(res));
    }
  }, [token, pfId]);

  useEffect(() => {
    if (token) {
      api.get("/v2/account/portfolios", {}, token).then(res => setPortfolios(res.result));
    }
  }, [token]);

  useEffect(() => {
    if (historyParams && token) {
      const params: QSParams = {
        currency: "tr10"
      };
      if (historyParams.period) {
        params.period = historyParams.period;
      }
      if (historyParams.category) {
        params.category = historyParams.category;
      }
      if (historyParams.asset) {
        params.asset = historyParams.asset;
      }
      api.get('/v2/account/assets/history', params, token).then(res => setHistory(res.result));
      api.get('/v2/account/assets/pnl/history', params, token).then(res => setHistoryPL(res.result));
    }
  }, [historyParams, token, pfId])

  if (!data) {
    return <Loading/>;
  }

  const setDefaultPortfolio = (p: string) => {
    api.post(`/admin/users/${userId}/portfolios/${p}/default`)
      .then(res => setUser(res.result));
  }

  return <div style={{display: "flex", justifyContent: "center", alignItems: "start"}}>
    <div style={{width: 400, margin: "0 50px"}}>
      <h2>User</h2>
      <Card>
        <Select style={{width: "100%"}} onChange={v => setDefaultPortfolio(v)} defaultValue={user.defaultPortfolioId}>
          {portfolios.map((p: any) => <Select.Option value={p.id}>{p.name}</Select.Option>)}
        </Select>
      </Card>
    </div>
    <div style={{width: 400, margin: "0 50px"}}>
      <h2>Assets</h2>
      <Card>
        <div style={{textAlign: "right"}}>{formatter.format(data.result.assets.total)} {data.result.assets.unit}</div>
        {
          data.result.assets.change && <div style={{textAlign: "right", color: data.result.assets.change < 0 ? "red" : "green"}}>
            {formatter.format(data.result.assets.change)} {data.result.assets.unit}
          </div>
        }
      </Card>
      {
        data.result.assets.items.map((item: any) => {
          return <Card>
            <div style={styles.twoCols}>
              <div><Tag color={"geekblue"}>{item.displayName}</Tag></div>
              <div>{formatter.format(item.total)}</div>
            </div>
            {
              item.children.map((c: any) => {
                return <div style={styles.twoCols}>
                  <div>
                    <Tag color={"cyan"}>{c.abbreviation} - {c.value}</Tag>
                    {
                      c.meanUnitPrice && <Tag color={"yellow"}>{c.meanUnitPrice}</Tag>
                    }
                  </div>
                  <div>{formatter.format(c.total)}</div>
                </div>
              })
            }
          </Card>
        })
      }
    </div>
    <div style={{width: 400, margin: "0 50px"}}>
      <h2>Asset History</h2>
      <Card>
        <Select style={{width: "100%"}} onChange={v => setHistoryParams({...historyParams, category: v})} defaultValue={"all"}>
          <Select.Option value={"all"}>Total assets</Select.Option>
        </Select>
        {
          history &&
          <Select style={{width: "100%"}} onChange={v => setHistoryParams({...historyParams, period: v})} defaultValue={history.params.period}>
            {history.periods.map((p: any) => <Select.Option value={p.id}>{p.label}</Select.Option>)}
          </Select>
        }
        {
          history &&
          <Select style={{width: "100%"}} onChange={v => setHistoryParams({...historyParams, category: v})} defaultValue={history.params.category}>
            {data.result.assets.items.map((p: any) => <Select.Option value={p.id}>{p.displayName}</Select.Option>)}
          </Select>
        }
        {
          history &&
          <Select style={{width: "100%"}} onChange={v => setHistoryParams({...historyParams, asset: v})} defaultValue={history.params.asset}>
            {data.result.assets.items.map((item: any) => {
              return item.children.map((child: any) => <Select.Option value={child.id}>{child.displayName}</Select.Option>)
            })}
          </Select>
        }
      </Card>
      <Card>
        {
          history && history.data.slice().reverse().map((data: any) => {
            return <div style={styles.twoCols}>
              <div>{data.date}</div>
              <div>{data.fmtValue}</div>
            </div>
          })
        }
      </Card>
      <Card>
        {
          historyPL && historyPL.data.slice().reverse().map((data: any) => {
            return <div style={styles.twoCols}>
              <div>{data.date}</div>
              <div>{data.fmtValue}</div>
            </div>
          })
        }
      </Card>
    </div>
  </div>;
}

export default UserAppViewPage;
