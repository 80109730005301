import { combineReducers } from 'redux'
import auth from './auth';
import config from './config';
import report from './report';
import users from './users';
import asset from './asset';
import message from './message';

export default combineReducers({
  auth: auth.reducer,
  config: config.reducer,
  message: message.reducer,
  report: report.reducer,
  users: users.reducer,
  asset: asset.reducer
});
