import React, {useEffect, useState} from 'react';
import {useAppDispatch, useSelector} from "store";
import {Button, Form, Select, Table} from "antd";
import Time from "components/Time";
import Loading from "components/Loading";
import {getAllUsersAction, UserSearchParams} from "reducers/users";
import {Link} from "react-router-dom";
import {selectUsers} from "reducers/selectors";

const {Column} = Table;

export const UsersListingPage = () => {
  const dispatch = useAppDispatch();
  const users = useSelector(selectUsers);
  const getStatus = useSelector(s => s.users.usersGetStatus);
  const [params, setParams] = useState<UserSearchParams>({id: undefined, role: undefined, skip: 0, limit: 20});

  useEffect(() => {
    dispatch(getAllUsersAction(params));
  }, [params]);

  if (getStatus !== "succeeded") {
    return <Loading/>;
  }

  const renderTime = (t: any) => {
    if (t) return <Time time={t}/>;
    return '-';
  }

  const roles = ["NOADS", "TESTER", "BYPASS_PURCHASE_VERIFICATION", "UNLIMITED_DEVICE_SYNC"];

  const handleFinish = (formValues: any) => {
    setParams({...params, ...formValues})
  }

  return (
    <div style={{display: 'flex', justifyContent: 'center', flexDirection: "column"}}>
      <h1>Users</h1>
      <Form onFinish={handleFinish} layout="inline">
        <Form.Item name="role">
          <Select style={{width: 200}}>
            {
              roles.map(r => <Select.Option value={r}>{r}</Select.Option>)
            }
          </Select>
        </Form.Item>
        <Form.Item><Button type="primary" htmlType="submit">List</Button></Form.Item>
      </Form>
      <Table dataSource={users} style={{marginTop: 20}} pagination={false} rowKey={d => d.id}>
        <Column title="ID" key="id" render={row => <Link to={`/users/${row.id}`}>{row.id}</Link>}/>
        <Column title="Devices" key="devices" render={row => Object.keys(row.devices).map(d => <span>{d}</span>)}/>
        <Column title="Subscription Date" key="creationDate" dataIndex="creationDate" render={renderTime}/>
        <Column title="Update Date" key="updateDate" dataIndex="updateDate" render={renderTime}/>
      </Table>
    </div>
  );
}

export default UsersListingPage;
