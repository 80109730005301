import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Row, Table} from "antd";
import Loading from "components/Loading";
import {Link, useParams} from "react-router-dom";
import useAPI from "hooks/API";
import {UserDetail} from "reducers/types";

const {Column} = Table;

export const UsersListingPage = () => {
  const {userId, otherUserId} = useParams<{ userId: string, otherUserId: string }>();
  const [user, setUser] = useState<UserDetail | null>(null);
  const [otherUser, setOtherUser] = useState<UserDetail | null>(null);
  const [copying, setCopying] = useState<boolean>(false);
  const api = useAPI();

  useEffect(() => {
    api.get(`/admin/users/${userId}`).then(res => setUser(res.result));
    api.get(`/admin/users/${otherUserId}`).then(res => setOtherUser(res.result));
  }, [userId, otherUserId]);

  if (!user || !otherUser || copying) {
    return <Loading/>;
  }

  const handleCopy = (pfId: string) => {
    setCopying(true);
    api.post(`/admin/users/${user.id}/portfolios/${pfId}/copy/${otherUser.id}`)
      .then(() => api.get(`/admin/users/${otherUserId}`))
      .then(res => {
        setOtherUser(res.result)
        setCopying(false);
      })
  }

  const printUserData = (user: UserDetail, showCopy: boolean) => {
    return <div>
      <h4>Devices</h4>
      <Table dataSource={user.deviceList} pagination={false} size={"small"}>
        <Column key={"id"} dataIndex={"id"} title={"#"}/>
        <Column key={"info"} render={r => `${r.os} - ${r.brand} - ${r.model}`} title={"Info"}/>
      </Table>
      <h4 style={{marginTop: "3em"}}>Portfolios</h4>
      <Table dataSource={user.portfolios} pagination={false} size={"small"}>
        <Column key={"id"} render={r => <span style={{fontSize: 8}}>{r.id}</span>} title={"#"}/>
        <Column key={"name"} dataIndex={"name"} title={"Name"}/>
        <Column key={"assetCount"} dataIndex={"assetCount"} title={"Asset Count"}/>
        {showCopy && <Column key={"action"} render={r => <Button size={"small"} danger onClick={() => handleCopy(r.id)}>Copy &raquo;</Button>}/>}
      </Table>
    </div>
  }

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Card key={"user"} bordered title={<Link to={`/users/${userId}`}>{userId}</Link>}>
          {printUserData(user, true)}
        </Card>
      </Col>
      <Col span={12}>
        <Card key={"user"} bordered title={<Link to={`/users/${otherUserId}`}>{otherUserId}</Link>}>
          {printUserData(otherUser, false)}
        </Card>
      </Col>
    </Row>
  );
}

export default UsersListingPage;
