import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Card} from "antd";
import useAPI from "hooks/API";
import Loading from "components/Loading";
import {ParitySourceData} from "reducers/types";
import ReactJson from "react-json-view";

export const ParitiesDetailPage = () => {
  const [paritySource, setParitySource] = useState<ParitySourceData | null>(null);
  const {id} = useParams<{ id: string }>();
  const api = useAPI();

  useEffect(() => {
    api.get(`/admin/parities/${id}`).then(setParitySource);
  }, [id]);

  if (!paritySource) return <Loading/>;

  return <div>
    <div><h2>Parity: {id}</h2></div>
    <div style={{display: 'flex', justifyContent: 'space-around'}}>
      <Card bordered style={{width: "80vw"}}>
        <ReactJson collapsed={false}
                   enableClipboard={false}
                   displayObjectSize={false}
                   displayDataTypes={false}
                   src={paritySource} />
      </Card>
    </div>
  </div>
}

export default ParitiesDetailPage;
