import APIService, {QSParams} from 'services/ApiService'
import {useAppDispatch} from "../store";
import {setError} from "../reducers/message";

const useAPI = () => {
  const dispatch = useAppDispatch();

  const api = new APIService();

  const errorHandler = (e: any) => {
    dispatch(setError(e.message || "Error"));
    console.log(e);
  }

  const get = (url: string, params?: QSParams, authHeader?: string) => api.get(url, params, authHeader).catch(errorHandler);

  const postFile = (url: string, file: File) => api.postFile(url, file).catch(errorHandler);

  const post = (url: string, data?: {}) => api.post(url, data).catch(errorHandler);

  const put = (url: string, data?: {}) => api.put(url, data).catch(errorHandler);

  const download = (url: string, filename: string) => api.download(url, filename).catch(errorHandler);

  const del = (url: string) => api.delete(url).catch(errorHandler);

  return {get, post, postFile, delete: del, download, put};
};

export default useAPI;
