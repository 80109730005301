import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "../store";
import ApiService from "services/ApiService";
import {ProgressState, ReportUser} from "./types";

export interface lineupState {
  usersGetStatus: ProgressState
  devicesGetStatus: ProgressState
  users: ReportUser[]
}

const initialState: lineupState = {
  usersGetStatus: "idle",
  devicesGetStatus: "idle",
  users: [],
}

const reducer = createSlice({
  name: 'report',
  initialState,
  reducers: {
    getUsersInit(state) {
      state.usersGetStatus = "loading"
    },
    getUsersSuccess(state, action: PayloadAction<ReportUser[]>) {
      state.users = action.payload;
      state.usersGetStatus = "succeeded"
    },
    getUsersError(state) {
      state.devicesGetStatus = "failed"
    },
  }
});

const {
  getUsersError,
  getUsersInit,
  getUsersSuccess,
} = reducer.actions;

export const getUsersAction = (startDate: string, endDate: string) => (dispatch: AppDispatch) => {
  dispatch(getUsersInit());
  const api = new ApiService();
  api.get(`/admin/report/users/`, {startDate, endDate})
    .then(res => {
      dispatch(getUsersSuccess(res.users))
    })
    .catch(() => dispatch(getUsersError()))
}

export default reducer;
