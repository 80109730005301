import React, {useState} from "react";
import {Button, Col, Form, Input, InputNumber, Radio, Row, Select} from "antd";
import {AssetCategory} from "../../reducers/types";
import {useSelector} from "../../store";
import {ASSET_ID_TRY, ASSET_ID_USD, availableTargetCurrencies} from "../../types";
import {twoOneFormStyle} from "../../components/Styles";
import AssetIcon from "../Assets/icon";

interface Props {
  onSave: (data: any) => void,
  showInitialPrice?: boolean,
}

const CreateNewParity = ({onSave, showInitialPrice}: Props) => {
  const categories: AssetCategory[] = useSelector(s => s.asset.categories);
  const [parityRequired, setParityRequired] = useState<boolean>(true);
  const [iconURL, setIconURL] = useState<string>("");
  const [form] = Form.useForm()

  const handleValuesChange = (values: any) => {
    const categoriesWithDefaultParity = ["stock_imkb", "stock_nasdaq"];
    setParityRequired(categoriesWithDefaultParity.indexOf(values.category) < 0);
    if (values.category === "stock_imkb") {
      form.setFieldsValue({targetAssetId: ASSET_ID_TRY});
    } else if (values.category === "stock_nasdaq") {
      form.setFieldsValue({targetAssetId: ASSET_ID_USD});
    }

    if (values.icon_url) {
      setIconURL(values.icon_url);
    }
  }

  const handleSave = (values: any) => {
    const req = {
      categorySlug: values.category,
      asset: {
        displayName: {
          en: values.desc_en,
          tr: values.desc_tr
        },
        abbreviation: {
          en: values.abbr_en,
          tr: values.abbr_tr
        },
        unit: {
          en: values.unit_en,
          tr: values.unit_tr
        },
        order: values.order || null,
        iconURL: values.icon_url,
      },
      parity: {
        targetAssetId: values.targetAssetId,
        isListening: true,
        initialRate: values.initialRate ? `${values.initialRate}` : null,
      }
    }
    onSave(req);
  }


  return <Form form={form} {...twoOneFormStyle.layout} onFinish={handleSave} onValuesChange={handleValuesChange}>
    <Form.Item>
      <h3>Asset Info</h3>
    </Form.Item>
    <Form.Item label="Category" name="category" rules={[{required: true}]}>
      <Select>
        {categories.filter(c => c.slug !== "stock").map(c => <Select.Option value={c.slug} key={c.slug}>{c.name}</Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item label="Abbreviation TR" name="abbr_tr" rules={[{required: true}]}>
      <Input autoComplete={"off"}/>
    </Form.Item>
    <Form.Item label="Abbreviation EN" name="abbr_en" rules={[{required: true}]}>
      <Input autoComplete={"off"}/>
    </Form.Item>
    <Form.Item label="Description TR" name="desc_tr" rules={[{required: true}]}>
      <Input autoComplete={"off"}/>
    </Form.Item>
    <Form.Item label="Description EN" name="desc_en" rules={[{required: true}]}>
      <Input autoComplete={"off"}/>
    </Form.Item>
    <Form.Item label="Unit TR" name="unit_tr" rules={[{required: true}]}>
      <Input autoComplete={"off"}/>
    </Form.Item>
    <Form.Item label="Unit EN" name="unit_en" rules={[{required: true}]}>
      <Input autoComplete={"off"}/>
    </Form.Item>
    <Form.Item label="Icon URL" name="icon_url">
      <Row>
        <Col span={12}><Input autoComplete={"off"}/></Col>
        <Col span={12}><AssetIcon iconURL={iconURL} /></Col>
      </Row>
    </Form.Item>
    <Form.Item label="Rank" name="order">
      <InputNumber autoComplete={"off"}/>
    </Form.Item>
    <Form.Item hidden={!parityRequired}>
      <h3>Parity Info</h3>
    </Form.Item>
    <Form.Item label="Currency" name="targetAssetId" rules={[{required: true}]} hidden={!parityRequired}>
      <Radio.Group>
        {availableTargetCurrencies.map(c => <Radio.Button key={c.value} value={c.value}>{c.label}</Radio.Button>)}
      </Radio.Group>
    </Form.Item>
    {
      showInitialPrice ?
        <Form.Item label="Initial Rate" name="initialRate" rules={[{required: true}]}>
          <InputNumber
            style={{width: 150}}
            autoComplete={"off"}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value!.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        : null
    }
    <Form.Item {...twoOneFormStyle.tailLayout}>
      <Button type="primary" htmlType="submit">
        Save
      </Button>
    </Form.Item>
  </Form>
}

export default CreateNewParity;
