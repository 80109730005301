const KEY = 'token';

export const getToken = () => localStorage.getItem(KEY) ?? undefined;

export const setToken = (token: string) => localStorage.setItem(KEY, token);

export const removeToken = () => localStorage.removeItem(KEY);

export const getSessionConfig: any = () => {
  try {
    return JSON.parse(sessionStorage.getItem("config") || "{}");
  } catch (e) {
    return {}
  }
}
export const isStaging = () => {
  const config = getSessionConfig();
  return config.isStaging;
}
export const updateSessionConfig = (config: any) => sessionStorage.setItem("config", JSON.stringify({...getSessionConfig(), ...config}));

export default {
  getToken, setToken, removeToken,
  isStaging,
  updateSessionConfig
}
