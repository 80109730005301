import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {useAppDispatch, useSelector} from "store";
import {AuthState, checkAuthAction} from "reducers/auth";
import {Layout} from "antd";
import AppContainer from "./container";
import Loading from "../../components/Loading";
import Header from './Header';
import LoginPage from "pages/Login";
import ErrorDisplayer from "./ErrorDisplayer";
import './style.less';

const {Footer, Content} = Layout;

export const App = () => {

  const auth: AuthState = useSelector(state => state.auth)
  const dispatch = useAppDispatch();

  if (auth.check === "notstarted") {
    dispatch(checkAuthAction())
  }

  if (auth.check !== "done") return <Loading />;

  return (
    <Router>
      <Layout>
        <Header />
        <Content className="layout-content">
          <AppContainer />
        </Content>
        <Footer style={{ textAlign: 'center' }}>©2021</Footer>
        <ErrorDisplayer />
      </Layout>
    </Router>
  );
}

export default App;
