import React from 'react';
import moment from "moment";
import {getUsersAction} from "reducers/report";
import {useAppDispatch, useSelector} from "store";
import {Button, DatePicker, Form, Table} from "antd";
import {Device, ReportUserAsset} from "reducers/types";
import Time from "components/Time";

const {Column} = Table;

export const ReportUsersPage = () => {
  const dispatch = useAppDispatch();
  const users = useSelector(s => s.report.users);
  const getStatus = useSelector(s => s.report.usersGetStatus);

  const devices = users.map(u => {
    return u.deviceList.map((device: Device) => {
      return {
        "deviceId": device.id,
        "installationDate": u.time.created,
        "platform": device.os,
        "subscriptionId": u.subscriptionId,
        "subscriptionDate": u.time.subscriptionStarted,
        "assets": u.assets
      }
    })
  }).flat();

  const handleFinish = (values: any) => {
    dispatch(getUsersAction(values.startDate.format(), values.endDate.format()));
  }

  const initialValues = {
    startDate: moment().add(-1, "days"),
    endDate: moment()
  }

  const renderAssets = (assets: ReportUserAsset[]) => {
    if (!assets || !assets.length) return null;
    return <Table size="small" dataSource={assets} pagination={false}>
      <Column title="Asset ID" key="id" dataIndex="id"/>
      <Column title="Asset Name" key="name" dataIndex="name"/>
      <Column title="Category" key="category" dataIndex="category"/>
      <Column title="Quantity" key="quantity" dataIndex="quantity"/>
    </Table>
  }

  const renderTime = (t: any) => {
    if (t) return <Time time={t}/>;
    return '-';
  }

  return (
    <div style={{display: 'flex', justifyContent: 'center', flexDirection: "column"}}>
      <h1>Users</h1>
      <Form onFinish={handleFinish} layout="inline" initialValues={initialValues}>
        <Form.Item name="startDate"><DatePicker/></Form.Item>
        <Form.Item name="endDate"><DatePicker/></Form.Item>
        <Form.Item><Button type="primary" htmlType="submit">List</Button></Form.Item>
      </Form>
      <Table dataSource={devices} style={{marginTop: 20}} loading={getStatus === "loading"} pagination={false} rowKey={d => d.deviceId}>
        <Column title="Device ID" key="deviceId" dataIndex="deviceId"/>
        <Column title="Installation Date" key="installationDate" dataIndex="installationDate" render={renderTime}/>
        <Column title="Platform" key="platform" dataIndex="platform"/>
        <Column title="Subscription ID" key="subscriptionId" dataIndex="subscriptionId"/>
        <Column title="Subscription Date" key="subscriptionDate" dataIndex="subscriptionDate" render={renderTime}/>
        <Column title="Assets" key="assets" dataIndex="assets" render={renderAssets}/>
      </Table>
    </div>
  );
}

export default ReportUsersPage;
