import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Col, Row, Card, Descriptions, Modal, Table, Button, Tabs, Switch} from "antd";
import useAPI from "hooks/API";
import Loading from "components/Loading";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {setError, setSuccess} from "reducers/message";
import {useAppDispatch} from "store";
import {UserDetail} from "reducers/types";
import ReactJson from "react-json-view";
import {DateTime} from "luxon";
import Time from "../../components/Time";

const {confirm} = Modal;
const {TabPane} = Tabs;

interface UserAppDataItem {
  id: string
  total: number
  displayName: string
  abbreviation: string
  change: number
  children: UserAppDataItem[]
}

interface UserAppDataPart {
  total: number
  displayName: string
  unit: string
  change: number
  items: UserAppDataItem[]
}

interface UserAppData {
  result: {
    assets: {
      assets: UserAppDataPart
    }
    pnl: {
      assets: UserAppDataPart
    }
  }
}

export const UserDetailPage = () => {
  const [user, setUser] = useState<UserDetail | null>(null);
  const [movedDevices, setMovedDevices] = useState<{ [k: string]: any }>({});
  const [deviceAPIResponses, setDeviceAPIResponses] = useState<{ [k: string]: any }>({});
  const dispatch = useAppDispatch();
  const {userId} = useParams<{ userId: string }>();
  const api = useAPI();

  console.log(movedDevices);

  const setUserData = (res: UserDetail) => {
    setUser(res)
  }

  const handleGetToken = (deviceId: string) => {
    api.get(`/admin/devices/${deviceId}/token`)
      .then(res => {
        if (res.success) {
          dispatch(setSuccess(res.message));
        }
      });
  }

  const handleCheckStore = (deviceId: string) => {
    api.get(`/admin/devices/${deviceId}/verify`).then(res => {
      setDeviceAPIResponses(prev => ({...prev, [deviceId]: res ? res.result : null}));
    });
  }

  const handleVerifyFromStore = (deviceId: string) => {
    api.post(`/admin/devices/${deviceId}/verify`).then(() => handleCheckStore(deviceId));
  }

  console.log(deviceAPIResponses);

  const handleCheckMoved = (deviceId: string) => {
    api.get(`/admin/devices/moved-${deviceId}`)
      .then(res => {
        if (res.result) {
          setMovedDevices({...movedDevices, [deviceId]: {moved: true, id: res.result.id}})
        } else {
          setMovedDevices({...movedDevices, [deviceId]: {moved: false}})
        }
      });
  }

  useEffect(() => {
    api.get(`/admin/users/${userId}`).then(res => setUserData(res.result));
    // api.get(`/admin/users/${userId}/history`).then(res => setUserHistory(res.result));
  }, []);

  const handleCopy = (pfId: string) => {
    api.post(`/admin/users/${userId}/portfolios/${pfId}/copy/test`)
      .then(res => {
        if (res.result) dispatch(setSuccess("User data copied"));
        else dispatch(setError("Failed to copy"));
      })
  }
  const handleDelete = () => {
    confirm({
      title: 'Delete user?',
      icon: <ExclamationCircleOutlined/>,
      content: <span>This action cannot be reverted</span>,
      onOk() {
        api.delete(`/admin/users/${userId}`)
          .then(res => {
            if (res.result) dispatch(setSuccess("User deleted"));
            else dispatch(setError("Failed to delete user"));
          })
      },
    });
  }

  if (user == null) return <Loading/>;
  const toggleRole = (role: string) => {
    if (user.roles && user.roles.indexOf(role) >= 0) {
      api
        .delete(`/admin/users/${user.id}/roles/${role}`)
        .then((res) =>
          setUser((prev: any) => ({ ...prev, roles: res.result.roles }))
        );
    } else {
      api
        .post(`/admin/users/${user.id}/roles/${role}`)
        .then((res) =>
          setUser((prev: any) => ({ ...prev, roles: res.result.roles }))
        );
    }
  };

  const roles = [
    { name: "tester", role: "TESTER" },
    { name: "no-ads", role: "NOADS" },
    { name: "promoted", role: "BYPASS_PURCHASE_VERIFICATION" },
    { name: "unlimited-sync", role: "UNLIMITED_DEVICE_SYNC" },
  ];

  const firstDeviceId = user.deviceList[0].id;

  return <div>
    <Row gutter={16}>
      <Col span={8}>
        <Card key={"user"} bordered title={`User: ${user.id}`}>
          <Button danger onClick={handleDelete}>Delete All User Data</Button>
          <br/>
          <br/>
          <br/>
          <Link to={`/users/${userId}/${firstDeviceId}/appview`} target={"_blank"}><Button type={"primary"}>User App View</Button></Link>
          <br/>
          <br/>
          <br/>

        </Card>
        <Card key={"roles"} bordered title={`User Roles`}>
          {
            roles.map(({role,name}) => <Row key={role}>
              <Col span={16}>{name}</Col>
              <Col span={8}><Switch checked={user.roles && user.roles.indexOf(role) >= 0} onChange={() => toggleRole(role)}/></Col>
            </Row>)
          }
        </Card>
      </Col>
      <Col span={16}>
        {
          user.deviceList.map(device => {
            return <Card style={{marginBottom: 20}} key={device.id} bordered title={`Device: ${device.id}`}>
              <Button type={"ghost"} onClick={() => handleGetToken(device.id)} style={{marginRight: "3em"}}>Get Token</Button>
              {
                movedDevices[device.id] ?
                  movedDevices[device.id].moved ?
                    <span>Device was moved from another account.&nbsp;<Link
                      to={`/users/${movedDevices[device.id].id}/compare/${userId}`}>Compare with old data</Link></span> :
                    <span>Not moved.</span>
                  :
                  <Button type={"ghost"} onClick={() => handleCheckMoved(device.id)}>Check Moved Device</Button>
              }
              <Button type={"ghost"} onClick={() => handleCheckStore(device.id)}>Check Store Status</Button>
              <Button type={"ghost"} onClick={() => handleVerifyFromStore(device.id)}>Update Store Status</Button>
              {
                deviceAPIResponses[device.id] ?
                  <div style={{marginTop: "2em"}}>
                    <ReactJson collapsed={false}
                               enableClipboard={false}
                               displayObjectSize={false}
                               displayDataTypes={false}
                               src={deviceAPIResponses[device.id]}/>
                  </div>
                  : null
              }
              {
                device.purchaseVerification ?
                  <div style={{marginTop: "3em"}}>
                    <h4>Verification:</h4>
                    <div>Last Check: <Time time={parseInt(device.purchaseVerification.lastCheckedAt)}/></div>
                    <div>Expiration: <Time time={parseInt(device.purchaseVerification.lastSeenExpiresAt)}/></div>
                    <div>Receipt: {device.purchaseVerification.purchaseId}</div>
                    <div>Subscription ID: {device.purchaseVerification.subscriptionId}</div>
                  </div> : null
              }
            </Card>
          })
        }
        <Card style={{marginBottom: 20}} key={"assets"} bordered title={`Portfolios`}>
          {user.portfolios.map(p => {
            return <div key={p.id} className={"div-row-spaced div--highlighted"}>
              <div><Link target="_blank" to={`/portfolios/${p.id}`}>{p.name}</Link></div>
              <div><Button type={"dashed"} onClick={() => handleCopy(p.id)}>Copy to test user</Button></div>
            </div>
          })}
        </Card>
      </Col>
    </Row>
  </div>;
}

export default UserDetailPage;
