import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import useAPI from "hooks/API";
import Loading from "components/Loading";
import {Card, Modal, Table, Button, Tabs} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {setError, setSuccess} from "reducers/message";
import {useAppDispatch, useSelector} from "store";
import TextArea from 'antd/lib/input/TextArea';

const {confirm} = Modal;
const {TabPane} = Tabs;

interface UserAppDataItem {
  id: string
  total: number
  displayName: string
  abbreviation: string
  change: number
  children: UserAppDataItem[]
}

interface Portfolio {
  id:string,
  name: string,
  userList: {id:string}[],
  assets: {
    [key: string]: {
      amount: number
      meanUnitPrice?: string
      changes: any[]
    }
  }
}

export const PortfolioPage = () => {
  const [pf, setPf] = useState<Portfolio | null>(null);
  const dispatch = useAppDispatch();
  const assets = useSelector(s => s.asset.assets);
  const parities = useSelector(s => s.asset.parities);
  const {id} = useParams<{ id: string }>();
  const api = useAPI();
  const [deviceId,setDeviceId] = React.useState<any>(null);

  const copyToDevice = ()=> {
    api.post(`/admin/users/${pf?.userList[0].id}/portfolios/${pf?.id}/copy/device/${deviceId}`)
      .then(res => {
        if (res.result) dispatch(setSuccess("User data copied"));
        else dispatch(setError("Failed to copy"));
      })
  }

  const getAssets = () => api.get(`/admin/portfolios/${id}`).then(res => setPf(res.result));

  useEffect(() => {
    getAssets();
  }, []);

  if (pf == null) return <Loading/>;

  const assetsTable = Object.keys(pf.assets).map(assetId => ({
    id: assetId,
    value: pf.assets[assetId].amount,
    meanUnitPrice: pf.assets[assetId].meanUnitPrice || "",
  }))

  const getParityRate = (assetId: string): number => {
    const asset = assets.find(a => a.assetId === assetId)!;
    const parity = parities.find(p => p.id === asset['parityId']);
    return parseInt(parity!['rate']);
  }

  const handleAssetDelete = (assetId: string) => {
    confirm({
      title: 'Delete asset?',
      icon: <ExclamationCircleOutlined/>,
      content: <span>This action cannot be reverted</span>,
      onOk() {
        api.delete(`/admin/portfolios/${id}/assets/${assetId}`)
          .then(res => {
            if (res.success) {
              dispatch(setSuccess(res.message));
              api.get(`/admin/portfolios/${id}`).then(res => {
                setPf(res.result)
              });
            }
          });
      },
    });
  }


  const handleDeleteHistory = (assetId: string) => {
    api.delete(`/admin/portfolios/${id}/history/assets/${assetId}`)
      .then(res => {
        if (res.success) {
          dispatch(setSuccess(res.message));
          // api.get(`/admin/portfolios/${id}/history`).then(res => setUserHistory(res.result));
        }
      });
  }

  const handleMove = (from: string, to: string) => {
    api.post(`/admin/portfolios/${id}/assets/${from}/move/${to}`)
      .then(res => {
        dispatch(setSuccess("Moved"));
        getAssets();
      });
  }

  const renderId = (row: any) => {
    if (row.id === "p:a:gold-silver:gr-gold") {
      return <span>{row.id} <Button size={"small"} onClick={() => handleMove("p:a:gold-silver:gr-gold", "p:a:gold-silver:gr-gold-spot")}>Move to Spot</Button></span>
    } else if (row.id === "p:a:gold-silver:gr-gold-spot") {
      return <span>{row.id} <Button size={"small"} onClick={() => handleMove("p:a:gold-silver:gr-gold-spot", "p:a:gold-silver:gr-gold")}>Move to GrandBazaar</Button></span>
    } else if (row.id === "p:a:stock:ideas") {
      return <span>{row.id} <Button size={"small"} onClick={() => handleMove("p:a:stock:ideas", "p:a:stock-imkb:skylp")}>Move to SKYLP</Button></span>
    } else if (row.id === "p:a:stock-imkb:skylp") {
      return <span>{row.id} <Button size={"small"} onClick={() => handleMove("p:a:stock-imkb:skylp", "p:a:stock:ideas")}>Move to IDEAS</Button></span>
    }
    return row.id;
  }

  return <div>
    <div style={{display:"flex", flexDirection:"row"}}>
      <TextArea autoSize onChange={(event)=>setDeviceId(event.target.value)} placeholder='device id'/>
      <Button onClick={copyToDevice} type="primary">Copy to device</Button>

    </div>
    <Card style={{marginBottom: 20}} key={"assets"} bordered title={`Assets`}>
      <Table dataSource={assetsTable} pagination={false} rowKey={r => r.id}>
        <Table.Column title="Asset" key={"assetId"} render={renderId}/>
        <Table.Column title="Value" key={"value"} dataIndex={"value"}/>
        <Table.Column key={"meanUnitPrice"} title="Mean Unit Price" dataIndex={"meanUnitPrice"}/>
        <Table.Column key={"rate"} title="Current Rate" render={row => getParityRate(row.id)}/>
        <Table.Column key={"diff"} title="Diff * Amount" render={row => (getParityRate(row.id) - row.meanUnitPrice) * row.value}/>
        <Table.Column key={"action"} render={row => <Button onClick={() => handleAssetDelete(row.id)} size={"small"} danger>delete</Button>}/>
      </Table>
    </Card>
    <Card style={{marginBottom: 20}} key={"history"} bordered title={`Asset History`}>
      <Tabs>
        {
          Object.keys(pf.assets).map(assetId => {
            const assetHistoryTable = pf.assets[assetId].changes.map((change: any) => ({
              id: assetId,
              since: change.since,
              value: change.value,
              unitPrice: change.unitPrice || ""
            }))
            return <TabPane tab={assetId} key={assetId}>
              <Table dataSource={assetHistoryTable} pagination={false} style={{marginBottom: 20}} rowKey={r => r.since}>
                <Table.Column width={100} title="Since" key={"since"} dataIndex={"since"}/>
                <Table.Column width={100} title="Value" key={"value"} dataIndex={"value"}/>
                <Table.Column width={150} key={"unitPrice"} title="Unit Price" dataIndex={"unitPrice"}/>
                <Table.Column width={150} key={"rate"} title="Current Rate" render={row => getParityRate(assetId)}/>
              </Table>
              <div style={{textAlign: "center"}}>
                <Button danger onClick={() => handleDeleteHistory(assetId)}>Delete history for this asset</Button>
              </div>
            </TabPane>
          })
        }
      </Tabs>
    </Card>
  </div>;
}

export default PortfolioPage;
