import React from 'react';
import {Button, Descriptions, Form, Radio, Select, Tabs} from "antd";
import {useSelector} from "store";
import CreateNewParity from "./new";
import {ASSET_ID_TRY, ASSET_ID_USD, availableTargetCurrencies} from "../../types";
import {twoOneFormStyle} from "../../components/Styles";

const {TabPane} = Tabs;

interface Props {
  source: string
  resource: { [k: string]: string }
  handleAdd: (data: any) => void
  handleExisting: (sourceAssetId: string, targetAssetId: string) => void
}

export const AddResource = ({source, resource, handleAdd, handleExisting}: Props) => {
  const assets = useSelector(s => s.asset.assets);


  const handleExistingSave = (values: any) => {
    handleExisting(values.sourceAssetId, values.targetAssetId);
  }

  const initialValues: { [k: string]: string } = {
    abbr_tr: resource.code,
    abbr_en: resource.code,
  };

  if (source === "foreks") {
    if (resource.category === "STOCK") {
      initialValues.unit_tr = "Adet";
      initialValues.unit_en = "Units";
      initialValues.desc_tr = resource.issuerName;
      initialValues.desc_en = resource.issuerName;
      initialValues.targetAssetId = ASSET_ID_TRY;
    } else {
      initialValues.unit_tr = resource.ticker;
      initialValues.unit_en = resource.ticker;
      initialValues.abbr_tr = resource.ticker;
      initialValues.abbr_en = resource.ticker;
      initialValues.desc_tr = resource.securityDesc;
      initialValues.desc_en = resource.securityDescEn;
    }
  } else {
    initialValues.targetAssetId = ASSET_ID_USD;
  }


  return (
    <div>
      <h2>Add new parity</h2>
      <div style={{display: "flex", alignItems: "flex-start", justifyContent: "space-around"}}>
        <div style={{width: 450}}>
          <Tabs defaultActiveKey="existing">
            <TabPane tab="Existing Asset" key="existing">
              <Form {...twoOneFormStyle.layout} onFinish={handleExistingSave} initialValues={initialValues}>
                <Form.Item label="Asset" name="sourceAssetId" rules={[{required: true, message: 'Please select an asset'}]}>
                  <Select showSearch={true}>
                    {assets.map(a => <Select.Option key={a.assetId} value={a.assetId}>{a.displayName.en} - {a.assetId}</Select.Option>)}
                  </Select>
                </Form.Item>
                <Form.Item label="Currency" name="targetAssetId" rules={[{required: true, message: 'Please select an asset'}]}>
                  <Radio.Group>
                    {availableTargetCurrencies.map(c => <Radio.Button key={c.value} value={c.value}>{c.label}</Radio.Button>)}
                  </Radio.Group>
                </Form.Item>
                <Form.Item {...twoOneFormStyle.tailLayout}>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </TabPane>
            <TabPane tab="Create New Asset" key="new">
              <CreateNewParity onSave={handleAdd} />
            </TabPane>
          </Tabs>
        </div>
        <div>
          <Descriptions column={1} bordered>
            {
              Object.keys(resource).map((k, v) => <Descriptions.Item label={k} key={k}>{Object.values(resource)[v]}</Descriptions.Item>)
            }
          </Descriptions>
        </div>
      </div>
    </div>
  );
}

export default AddResource;
