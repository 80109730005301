import { Menu } from "antd";
import * as React from "react";
import { Link, Route, Switch } from "react-router-dom";
import "./SubMenu.less";

export interface ISubMenuItem {
  path: string;
  Component: React.ComponentType;
  label: string;
  key?: React.Key;
}
interface ISubMenuProps {
  subMenuItems: ISubMenuItem[];
  pathPrefix: string;
  className?: string;
}

export default function SubMenu({
  subMenuItems,
  pathPrefix,
  className,
}: ISubMenuProps) {
  return (
    <div className={"sub-menu-container " + className}>
      <Menu theme="dark" mode="horizontal">
        {subMenuItems.map(({ path, label, key }) => (
          <Menu.Item key={key ?? label}>
            <Link to={pathPrefix + path}>{label}</Link>
          </Menu.Item>
        ))}
      </Menu>
      <Switch>
        {subMenuItems.map(({ path, Component }) => (
          <Route path={pathPrefix + path} component={Component} />
        ))}
      </Switch>
    </div>
  );
}
