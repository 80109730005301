import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "store";
import session from "services/SessionService";
import APIService from "services/ApiService";
import {ProgressState} from "./types";

type AuthCheckStatus = "notstarted" | "inprogress" | "done"

export interface AuthState {
  loginStatus: ProgressState,
  check: AuthCheckStatus,
  isAuth: boolean
  role: string
}

const initialState: AuthState = {
  loginStatus: 'idle',
  check: "notstarted",
  isAuth: false,
  role: "NONE"
}

const reducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<string>) {
      state.check = "done"
      state.isAuth = true;
      session.setToken(action.payload);
    },
    removeAuth(state) {
      state.check = "done"
      state.isAuth = false;
      session.removeToken();
    },
    authCheckStarted(state) {
      state.check = "inprogress"
    },
    loginStart(state) {
      state.loginStatus = 'loading'
    },
    loginSuccess(state, action: PayloadAction<string>) {
      state.loginStatus = 'succeeded'
      state.isAuth = true;
      session.setToken(action.payload);
    },
    loginFailure(state) {
      state.loginStatus = 'failed'
    }
  }
});

const {
  authCheckStarted,
  loginStart,
  loginSuccess,
  loginFailure
} = reducer.actions;

export const checkAuthAction = () => (dispatch: AppDispatch) => {
  dispatch(authCheckStarted());
  const token = session.getToken()
  if (token) dispatch(setAuth(token))
  else dispatch(removeAuth())
};

export const loginAction = (username: string, password: string) => (dispatch: AppDispatch) => {
  const header = btoa(username + ":" + password)
  dispatch(loginStart())
  const api = new APIService();
  api.get('/admin/me', {}, `Basic ${header}`)
    .then(() => dispatch(loginSuccess(header)))
    .catch(() => dispatch(loginFailure()))
}

export const {
  setAuth,
  removeAuth
} = reducer.actions

export default reducer;
