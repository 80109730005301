import React from 'react';
import useAPI from "../../hooks/API";
import { useHistory } from "react-router-dom";
import {DateTime} from "luxon";
import CreateNewParity from "../SourceChanges/new";
import {setSuccess} from "../../reducers/message";
import {useAppDispatch} from "../../store";
import {getAssetsAction, getParitiesAction} from "../../reducers/asset";

export const AddAssetsPage = () => {
  const api = useAPI();
  const dispatch = useAppDispatch();
  let history = useHistory();

  const now = () => DateTime.now().toUnixInteger();

  const handleSave = (data: any) => {
    data.source = "placeholder";
    data.sourceId = `${now()}`;
    data.parity.isListening = false;

    api.post(`/admin/assets`, data)
      .then(res => {
        if (res.success) {
          dispatch(getAssetsAction());
          dispatch(getParitiesAction());
          dispatch(setSuccess(<span>New asset and parity saved. Asset: {res.code}</span>));
          history.push("/assets")
        }
      })
  }

  return <CreateNewParity onSave={handleSave} showInitialPrice={true}/>
}

export default AddAssetsPage;
