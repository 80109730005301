import * as React from "react";
import { Menu } from "antd";
import { Link, Route, Router, Switch } from "react-router-dom";
import SubMenu, { ISubMenuItem } from "components/SubMenu/SubMenu";
import Portfolio from "./Subpages/Portfolio/Portfolio";

const subMenuItems: ISubMenuItem[] = [
  {
    label: "Portfolio",
    path: "/portfolio",
    Component: Portfolio,
  },
  {
    label: "Template",
    path: "/template",
    Component: () => <div>template</div>,
  },
];
export default function Find() {
  return (
    <SubMenu
      pathPrefix="/find"
      subMenuItems={subMenuItems}
    />
  );
}
