import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "store";
import APIService from "services/ApiService";
import {AssetCategory, FinancialAsset, FinancialResource, MapOf, Parity, ProgressState} from "./types";

export interface State {
  assetsGetStatus: ProgressState
  paritiesGetStatus: ProgressState
  addResourceStatus: ProgressState
  resourcesGetStatus: ProgressState
  addAssetStatus: ProgressState
  categoriesStatus: ProgressState
  resources: MapOf<FinancialResource>
  assets: FinancialAsset[]
  parities: Parity[]
  categories: AssetCategory[]
}

const initialState: State = {
  assetsGetStatus: "idle",
  paritiesGetStatus: "idle",
  addAssetStatus: "idle",
  resourcesGetStatus: "idle",
  addResourceStatus: "idle",
  categoriesStatus: "idle",
  resources: {},
  assets: [],
  categories: [],
  parities: []
}

const reducer = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    getAssetsInit(state) {
      state.assetsGetStatus = "loading"
    },
    getAssetsSuccess(state, action: PayloadAction<FinancialAsset[]>) {
      state.assets = action.payload;
      state.assetsGetStatus = "succeeded"
    },
    getAssetsError(state) {
      state.assetsGetStatus = "failed"
    },
    getAssetCategoriesSuccess(state, action: PayloadAction<AssetCategory[]>) {
      state.categories = action.payload;
      state.categoriesStatus = "succeeded";
    },
    getAssetCategoriesInit(state) {
      state.categoriesStatus = "loading";
    },
    getAssetCategoriesError(state) {
      state.categoriesStatus = "failed";
    },
    getParitiesInit(state) {
      state.paritiesGetStatus = "loading"
    },
    getParitiesSuccess(state, action: PayloadAction<Parity[]>) {
      state.parities = action.payload;
      state.paritiesGetStatus = "succeeded"
    },
    getParitiesError(state) {
      state.paritiesGetStatus = "failed"
    },
    getResourcesInit(state) {
      state.resourcesGetStatus = "loading"
    },
    getResourcesSuccess(state, action: PayloadAction<MapOf<FinancialResource>>) {
      state.resources = action.payload;
      state.resourcesGetStatus = "succeeded"
    },
    getResourcesError(state) {
      state.resourcesGetStatus = "failed"
    },
  }
});

const {
  getAssetsError,
  getAssetsInit,
  getAssetsSuccess,
  getParitiesError,
  getParitiesInit,
  getParitiesSuccess,
  getResourcesError,
  getResourcesInit,
  getResourcesSuccess,
  getAssetCategoriesInit,
  getAssetCategoriesSuccess,
  getAssetCategoriesError,
} = reducer.actions;

export const getAssetsAction = () => (dispatch: AppDispatch) => {
  dispatch(getAssetsInit());
  const api = new APIService();
  api.get(`/admin/assets`)
    .then(res => {
      dispatch(getAssetsSuccess(res))
    })
    .catch(() => dispatch(getAssetsError()))
}

export const getAssetCategoriesAction = () => (dispatch: AppDispatch) => {
  dispatch(getAssetCategoriesInit());
  const api = new APIService();
  api.get(`/admin/assets/categories`)
    .then(res => {
      dispatch(getAssetCategoriesSuccess(res))
    }).catch(() => dispatch(getAssetCategoriesError()))
}

export const getParitiesAction = () => (dispatch: AppDispatch) => {
  dispatch(getParitiesInit());
  const api = new APIService();
  api.get(`/admin/parities`)
    .then(res => {
      dispatch(getParitiesSuccess(res))
    })
    .catch(() => dispatch(getParitiesError()))
}

export const updateParityAction = (id: string, isListening: boolean) => (dispatch: AppDispatch, getState: () => RootState) => {
  const api = new APIService();
  api.post(`/admin/parities/${id}`, {isListening})
    .then(() => {
      // const state = getState();
      // const newParities = state.asset.parities.map(p => p.id === id ? {...p, isListening} : p);
      // dispatch(getParitiesSuccess(newParities))
    })
}

export const getResourcesAction = () => (dispatch: AppDispatch) => {
  dispatch(getResourcesInit());
  const api = new APIService();
  api.get(`/admin/assets/resources`)
    .then(res => {
      dispatch(getResourcesSuccess(res))
    })
    .catch(() => dispatch(getResourcesError()))
}

export default reducer;
