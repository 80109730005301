import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Select, Table, Button, Modal, Form, Input} from "antd";
import {CMCSourceParity, CMCSourceParityDressed, Parity} from "reducers/types";
import useAPI from "hooks/API";
import Loading from "components/Loading";
import {useAppDispatch, useSelector} from "store";
import {getParitiesAction} from "reducers/asset";

const {Column} = Table;

export const CMCChangesPage = () => {
  const assets = useSelector(s => s.asset.assets);
  const allParities = useSelector(s => s.asset.parities);
  const parities = allParities.filter(p => p.source === "coinmarketcap");
  const dispatch = useAppDispatch();
  const cryptoAssets = assets.filter(a => a.category === "CRYPTO");
  const [savingParityId, setSavingParityId] = useState<string>("0");
  const [filter, setFilter] = useState<string>("all");
  const [search, setSearch] = useState<string>("");
  const [sourceParities, setSourceParities] = useState<{[k: string]: CMCSourceParity} | null>(null);
  const api = useAPI();

  useEffect(() => {
    api.get("/admin/source/coinmarketcap/parities").then(setSourceParities);
  }, []);

  if (!sourceParities) return <Loading/>;

  const paritiesById: {[k: string]: Parity} = {};
  parities?.forEach(p => paritiesById[p.id] = p)

  const filterOptions = [
    {"title": "All", "value": "all"},
    {"title": "Existing", "value": "existing"},
    {"title": "New", "value": "new"},
  ];

  const handleSaveParity = (values: any) => {
    const data = {
      "source": "coinmarketcap",
      "sourceId": `${savingParityId}`,
      "sourceAssetId": values.assetId,
      "targetAssetId": "p:a:currency:usd",
    }
    api.post("/admin/parities", data)
      .then(() => {
        dispatch(getParitiesAction());
        setSavingParityId("0");
      });
  }

  const renderActions = (p: CMCSourceParityDressed) => {
    if (p.exists) {
      return <div>Saved for asset: <Link to={`/assets/${p.parity?.sourceAssetId}`}>{p.parity?.sourceAssetId}</Link></div>
    }
    return <div>
      <Button type={"primary"} onClick={() => setSavingParityId(p.cmcId)}>Save as parity</Button>
    </div>
  }

  const list: CMCSourceParityDressed[] = Object.values(sourceParities).map(p => {
    const parityId = `p:p:coinmarketcap:${p.cmcId}`;
    const exists = parityId in paritiesById;
    const parity = exists ? paritiesById[parityId] : undefined;
    return {...p, exists, parity}
  }).filter(item => {
    if (!search) return true;
    return item.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 || item.symbol.indexOf(search.toUpperCase()) >= 0
  })

  return (
    <div>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <h1>Coin Market Cap Parities</h1>
        <div>
          <Input style={{width: 200}} value={search} onChange={e => setSearch(e.target.value)}/>
          <Select style={{width: 200}} value={filter} onChange={e => setFilter(e)}>
            {filterOptions.map(o => <Select.Option value={o.value} key={o.value}>{o.title}</Select.Option>)}
          </Select>
        </div>
      </div>
      <Table dataSource={list} rowKey={r => r.cmcId}>
        <Column title="ID" key="id" dataIndex="cmcId"/>
        <Column title="Symbol" key="symbol" dataIndex="symbol"/>
        <Column title="Name" key="name" dataIndex="name"/>
        <Column key="actions" render={renderActions}/>
      </Table>

      <Modal title="Save as parity" visible={savingParityId !== "0"} footer={null} onCancel={() => setSavingParityId("0")}>
        {
          savingParityId !== "0" &&
          <Form layout="vertical" onFinish={handleSaveParity}>
            <Form.Item label="Name">{sourceParities[savingParityId].name}</Form.Item>
            <Form.Item name="assetId" label="Asset" rules={[{required: true, message: 'Please select an asset'}]}>
              <Select style={{}} showSearch={true}>
                {cryptoAssets.map(a => <Select.Option key={a.id} value={a.assetId}>{a.displayName.en} - {a.assetId}</Select.Option>)}
              </Select>
            </Form.Item>
            <Form.Item style={{textAlign: 'center'}}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        }
      </Modal>
    </div>
  );
}

export default CMCChangesPage;
