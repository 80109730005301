import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {useAppDispatch} from "store";
import {Button, Form, Input, Pagination, Spin, Table} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import moment from "moment";
import useAPI from "hooks/API";
import {setError} from "reducers/message";
import {ReportUserMessage} from "reducers/types";
import Loading from "components/Loading";

const {Column} = Table;

export const ReportUsersPage = () => {
  const dispatch = useAppDispatch();
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [messages, setMessages] = useState<null | ReportUserMessage[]>(null);
  const api = useAPI();

  const pageSize = 50;
  const skip = (page - 1) * pageSize;

  const getMessages = (email?: string) => {
    const queryEmail = email ? `&email=${email}` : '';
    api.get(`/admin/report/messages?skip=${skip}&count=${pageSize}${queryEmail}`)
      .then(res => {
        setMessages(res.messages);
      })
      .catch(() => dispatch(setError("Failed to fetch messages")))
  }

  useEffect(() => {
    getMessages();
  }, [dispatch, page]);

  const handleDelete = (id: string) => {
    setDeletingId(id);
    api.delete(`/admin/report/messages/${id}`)
      .then(() => {
        if (messages) {
          setMessages(messages.filter(m => m.id !== id));
        }
      })
      .finally(() => {
        setDeletingId(null);
      })
  }

  const handleSearch = (values: any) => {
    getMessages(values.email);
  }

  const renderAction = (row: ReportUserMessage) => {
    if (deletingId === row.id) {
      return <Spin/>;
    }
    return <Button danger type="primary" shape={"circle"} size={"small"} onClick={() => handleDelete(row.id)} icon={<DeleteOutlined />} />
  }

  if (!messages) {
    return <Loading/>;
  }

  return (
    <div style={{display: 'flex', justifyContent: 'center', flexDirection: "column"}}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
        <h1>User Messages</h1>
        <div>
          <Form
            name="customized_form_controls"
            layout="inline"
            onFinish={handleSearch}
            >
            <Form.Item name="email" label="Email">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Table dataSource={messages} style={{marginTop: 20}} pagination={false} rowKey={r => r.id} bordered size="small">
        <Column title="Received At" key="receivedAt" dataIndex="receivedAt" render={t => moment(t).toISOString(true)}/>
        <Column title="Device ID" key="deviceId" render={row => <Link to={`/users/${row.userId}`}>{row.deviceId}</Link>}/>
        {/*<Column title="Subscription" key="subscriptionId" dataIndex="subscriptionId" render={s => s || '-'}/>*/}
        <Column title="Email" key="email" render={row => <Button type={"text"} onClick={() => getMessages(row.email)}>{row.email}</Button>}/>
        <Column title="Message" key="message" dataIndex="message"/>
        <Column title="Message" key="action" render={renderAction} align={"center"}/>
      </Table>
      <Pagination current={page} pageSize={pageSize} total={1000} showSizeChanger={false} onChange={(page) => setPage(page)}/>
    </div>
  );
}

export default ReportUsersPage;
