import { useMemo, useState } from "react";

const charRegex = /([/|.|\w|\s])*/;

export default function useContentSearch({
  payload = [],
  searchKeys = [],
}: {
  payload?: any[];
  searchKeys?: string[];
}) {
  const [text, setText] = useState("");
  const searchRegex = useMemo(
    () =>
      text
        ? Array.from(text).reduce(
            (regex, char) => new RegExp(regex.source + char + charRegex.source),
            new RegExp("")
          )
        : null,
    [text]
  );
  const result = useMemo(
    () =>
      payload.filter(
        (item) =>
          !searchRegex || searchKeys.some((key) => item[key].match(searchRegex)) //item[key] should be checked whether it is a string
      ),
    [text, payload, searchKeys]
  );
  return { setText, result };
}
