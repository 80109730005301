import {Action, AnyAction, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReactNode} from "react";

interface RejectedAction extends Action {
  payload: string
}

interface SuccessAction extends Action {
  payload: any
}

export interface MessageState {
  error: string|ReactNode
  success: string|ReactNode
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('Error')
}

function isSuccessAction(action: AnyAction): action is SuccessAction {
  return action.type.endsWith('Success')
}

const initialState: MessageState = {
  error: '' as string,
  success: '' as string,
}
const reducer = createSlice({
  name: 'error',
  initialState,
  reducers: {
    clearMessages(state) {
      state.error = '';
      state.success = '';
    },
    setSuccess(state, action: PayloadAction<string|ReactNode>) {
      state.success = action.payload
    },
    setError(state, action: PayloadAction<string|ReactNode>) {
      state.error = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isRejectedAction, (state, action) => {
        state.error = action.payload;
        return state;
      })
      .addMatcher(isSuccessAction, (state, action) => {
        if (action.payload && action.payload.message) {
          state.success = action.payload.message;
        }
        return state;
      })
  }
});

export const { clearMessages, setSuccess, setError } = reducer.actions;

export default reducer;
