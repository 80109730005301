import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button, Card, DatePicker, Form, Input, InputNumber, Select, Col, Row} from "antd";
import useAPI from "hooks/API";
import Loading from "components/Loading";
import {FinancialAsset, Parity} from "reducers/types";
import {useAppDispatch, useSelector} from "store";
import {setError, setSuccess} from "reducers/message";
import {Dictionary} from "@reduxjs/toolkit";
import AssetIcon from "./icon";

export const AssetDetailPage = () => {
  const dispatch = useAppDispatch();
  const assets: FinancialAsset[] = useSelector(s => s.asset.assets);
  const parities: Parity[] = useSelector(s => s.asset.parities);
  const [asset, setAsset] = useState<FinancialAsset | null>(null);
  const [iconURL, setIconURL] = useState<string>("");
  const [fixQuantityLoading, setFixQuantityLoading] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const {id} = useParams<{ id: string }>();
  const api = useAPI();

  const assetsById: { [k: string]: FinancialAsset } = {};
  assets.forEach(asset => {
    assetsById[asset.assetId] = asset
  });

  useEffect(() => {
    api.get(`/admin/assets/${id}`).then(data => {
      setAsset(data);
      setIconURL(data.iconURL);
    });
  }, [id]);

  if (!asset) return <Loading/>;

  const filteredParities = parities.filter(p => p.sourceAssetId === id || p.targetAssetId === id);

  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
  };
  const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
  };

  const handleSave = (values: any) => {
    const data: Dictionary<any> = {
      parityId: values.parityId,
      displayNameTR: values.displayNameTR,
      displayNameEN: values.displayNameEN,
    };
    if (iconURL) {
      data.iconURL = iconURL;
    }
    setUpdating(true);
    api.post(`/admin/assets/${id}`, data)
      .then(res => {
        setUpdating(false);
        if (res) dispatch(setSuccess("Asset data updated"))
        else dispatch(setError("Failed to update asset"))
      });
  }

  const handleValuesChange = (values: any) => {
    setIconURL(values.iconURL);
  }

  const handleQuantityUpdate = (values: any) => {
    const data = {
      "portfolioId": values.portfolioId || null,
      "assetId": asset.assetId,
      "multiplier": `${values.multiplier}`,
      "untilDate": values.untilDate.format('YYYY-MM-DD')
    };
    setFixQuantityLoading(true);
    api.post(`/admin/portfolios/fix-quantity`, data)
      .then(res => {
        setFixQuantityLoading(false);
        if (res) dispatch(setSuccess(res.code))
        else dispatch(setError("Failed"))
      });
  }

  const renderParity = (p: Parity) => {
    const sourceAsset: FinancialAsset = assetsById[p.sourceAssetId];
    const targetAsset: FinancialAsset = assetsById[p.targetAssetId];
    if (!sourceAsset || !targetAsset) {
      console.log("No source or target for parity: " + p.id);
      return `${p.id}`;
    }
    return `${p.id} (${sourceAsset['unit']['tr']} -> ${targetAsset['unit']['tr']})`;
  }

  const initialValues = {
    parityId: asset.parityId,
    iconURL: asset.iconURL,
    displayNameTR: asset.displayName.tr,
    displayNameEN: asset.displayName.en,
  };

  return <div>
    <div><h2>Asset: {asset.assetId}</h2></div>
    <div style={{display: 'flex', justifyContent: 'space-around'}}>
      <Card bordered title={"Update Asset"} style={{width: "40vw"}}>
        <Form {...layout} onFinish={handleSave} initialValues={initialValues} onValuesChange={handleValuesChange}>
          <Form.Item label="Parity" name="parityId">
            <Select showSearch>
              {filteredParities.map(p => <Select.Option value={p.id} key={p.id}>{renderParity(p)}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item label="Icon URL" name="iconURL">
            <Row>
              <Col span={12}><Input /></Col>
              <Col span={12}><AssetIcon iconURL={iconURL} /></Col>
            </Row>
          </Form.Item>
          <Form.Item label="Description TR" name="displayNameTR" rules={[{required: true}]}>
            <Input autoComplete={"off"}/>
          </Form.Item>
          <Form.Item label="Description EN" name="displayNameEN" rules={[{required: true}]}>
            <Input autoComplete={"off"}/>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={updating}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Card bordered title={"Update Asset Quantity in Portfolios"} style={{width: "40vw"}}>
        <Form {...layout} onFinish={handleQuantityUpdate} initialValues={{parityId: asset.parityId}}>
          <Form.Item label="Portfolio ID" name="portfolioId">
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item label="Multiplier" name="multiplier">
            <InputNumber autoComplete="off" />
          </Form.Item>
          <Form.Item label="Until" name="untilDate">
            <DatePicker/>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={fixQuantityLoading}>
              Update
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  </div>
}

export default AssetDetailPage;
