import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface State {
  timeFormat: string,
  isStaging: boolean
}

const initialState: State = {
  timeFormat: "relative",
  isStaging: false
}

const reducer = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setTimeFormat(state, action: PayloadAction<string>) {
      state.timeFormat = action.payload;
    },
    setStaging(state, action: PayloadAction<boolean>) {
      state.isStaging = action.payload;
    }
  }
});

export const {
  setTimeFormat,
  setStaging
} = reducer.actions;

export default reducer;
