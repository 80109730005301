import React, {useEffect, useState} from 'react';
import moment from "moment";
import {Button, DatePicker, Form} from "antd";
import useAPI from "hooks/API";
import {setError} from "reducers/message";
import {useAppDispatch} from "store";
import {Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@material-ui/core";

export const ReportDevicesPage = () => {
  const api = useAPI();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const initialData = {
        "fmtTotalSum": "",
        "items": [
            {
                "categoryName": "",
                "deviceCount": 0,
                "devicePercentage": 0.0,
                "sum": "",
                "fmtSum": "",
                "sumPercentage": 0.0
            }
        ],
        "threshold": 0,
        "totalDevice": 0,
        "totalSum": ""
    }
  const [payload, setPayload] = useState(initialData);
  const [existReport, setExistReport] = useState(false);
  const [threshold, setThreshold] = useState("");
  const [updatedThreshold, setUpdatedThreshold] = useState("");

    const getThreshold = () => api.get("/admin/reports/threshold").then(res => {
        setThreshold(res.result)
    });

    const updateThreshold = (value: any) => api.put("/admin/reports/threshold", value).then(res => {
        setThreshold(res.result)
        setUpdatedThreshold("")
    });


    useEffect(() => {
        getThreshold();

    }, []);

  const handleFinish = (values: any) => {
    setLoading(true);
    const dt = values.date.format('YYYY-MM-DD');
    api.get(`/admin/reports?date=${dt}`)
        .then((res) => {
            console.log(JSON.stringify(res, null, 2))
            setPayload(res.result);
            setExistReport(res.result != null);
            setLoading(false)
        })
        .catch(() => {
          dispatch(setError("Failed to get report"));
          setLoading(false);
        })

  }

  const initialValues = {
    date: moment().add(-1, "days")
  }


    const useStyles = makeStyles({
        table: {
            maxWidth: 650,
        },
    });

    const classes = useStyles();

    function handleUpdateThreshold() {
        const body = {"threshold" : updatedThreshold}
        updateThreshold(body)
        console.log(updatedThreshold)
    }

    return (
    <div style={{display: 'flex', justifyContent: 'center', flexDirection: "column"}}>
      <h1>Reports</h1>
      <Form onFinish={handleFinish} layout="inline" initialValues={initialValues}>
        <Form.Item name="date"><DatePicker/></Form.Item>
        <Form.Item><Button type="primary" htmlType="submit" loading={loading}>List</Button></Form.Item>
      </Form>

        <br/>
        <div>
            <Grid container>
                <Grid item xs={2}>
                    <h3>Current Threshold</h3>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled
                        id="outlined-disabled"
                        value={threshold}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={2}>
                    <h3>New Threshold</h3>
                </Grid>
                <Grid item xs={2}>
                        <TextField id="standard-basic"  value={updatedThreshold}  type="number" onChange={(e) => {
                            setUpdatedThreshold(e.target.value);
                        }}/>
                </Grid>
                <Button  color="primary" onClick={handleUpdateThreshold}>Update</Button>
            </Grid>


        </div>

        <br/>

        <div>
            {existReport  &&
            <TableContainer >
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center" colSpan={2}>HOLDERS</TableCell>
                            <TableCell align="center" colSpan={2}>WORTH</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Count</TableCell>
                            <TableCell align="right">%</TableCell>
                            <TableCell align="right">TL</TableCell>
                            <TableCell align="right">%</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {payload.items.map((row) => (
                            <TableRow key={row.categoryName}>
                                <TableCell component="th" scope="row">
                                    {row.categoryName}
                                </TableCell>
                                <TableCell align="right">{row.deviceCount}</TableCell>
                                <TableCell align="right">{row.devicePercentage}</TableCell>
                                <TableCell align="right">{row.fmtSum}</TableCell>
                                <TableCell align="right">{row.sumPercentage}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">{payload.totalDevice}</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">{payload.fmtTotalSum}</TableCell>
                            <TableCell align="center">100%</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">Threshold: {payload.threshold}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>}
            {
                !existReport && <h1 style={{color: "red"}}>Report Not Found</h1>
            }
        </div>

    </div>
  );
}

export default ReportDevicesPage;
