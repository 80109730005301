import React from "react";
import moment from "moment";
import {useAppDispatch, useSelector} from "store";
import {setTimeFormat} from "reducers/config";

interface Props {
  time: number
}

const Time = ({time}: Props) => {
  const timeFormat = useSelector(s => s.config.timeFormat);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    const newFormat = timeFormat === "relative" ? "datetime" : "relative";
    dispatch(setTimeFormat(newFormat));
  }

  if (timeFormat === "relative") {
    return <span style={{cursor: "pointer", fontSize: 12}} onClick={handleClick}>{moment(time).fromNow()}</span>
  }

  return <span style={{cursor: "pointer", fontSize: 12}} onClick={handleClick}>{moment(time).format('lll')}</span>
}

export default Time;