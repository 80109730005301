import {AuthState} from "reducers/auth";
import {useAppDispatch, useSelector} from "store";
import Routes from "pages/App/Routes";
import React, {useEffect} from "react";
import {Alert} from "antd";
import {getAssetCategoriesAction, getAssetsAction, getParitiesAction} from "reducers/asset";
import {AssetCategory, FinancialAsset, Parity} from "reducers/types";
import Loading from "components/Loading";

const AppContainer = () => {
  const auth: AuthState = useSelector(state => state.auth)
  const assets: FinancialAsset[] = useSelector(s => s.asset.assets);
  const parities: Parity[] = useSelector(s => s.asset.parities);
  const categories: AssetCategory[] = useSelector(s => s.asset.categories);
  const statusAsset = useSelector(s => s.asset.assetsGetStatus);
  const statusParity = useSelector(s => s.asset.paritiesGetStatus);
  const statusCategories = useSelector(s => s.asset.categoriesStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auth.isAuth) {
      dispatch(getAssetsAction());
      dispatch(getParitiesAction());
      dispatch(getAssetCategoriesAction());
    }
  }, [dispatch, auth.isAuth]);

  if (statusAsset === "failed" || statusParity === "failed" || statusCategories === "failed") {
    return <div style={{display: "flex", height: "100vh", justifyContent: "center", alignItems: "center"}}>
      <Alert message="Failed to connect to API" type="error" />
    </div>
  }

  if (auth.isAuth) {
    if (!assets || assets.length === 0) return <Loading/>;
    if (!parities || parities.length === 0) return <Loading/>;
    if (!categories || categories.length === 0) return <Loading/>;
  }

  return <Routes isAuth={auth.isAuth}/>;
}

export default AppContainer;
