import React from "react";

interface Props {
  iconURL: string;
}
const AssetIcon = ({iconURL}: Props) => {
  if (!iconURL) return null;

  return <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
    <img src={iconURL} style={{maxWidth: 64}}/>
  </div>
}
export default AssetIcon;
