import {useEffect} from 'react';
import {useAppDispatch, useSelector} from "store";
import {message} from "antd";
import {MessageState, clearMessages} from "reducers/message";

export const ErrorDisplayer = () => {

  const messages: MessageState = useSelector(state => state.message)
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (messages.error) {
      message.error(messages.error);
      dispatch(clearMessages())
    } else if (messages.success) {
      message.success(messages.success)
      dispatch(clearMessages())
    }
  }, [dispatch, messages.error, messages.success])

  return null;
}

export default ErrorDisplayer;
