import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useAppDispatch, useSelector} from "store";
import {Tag, Input, Switch, Table, Select, Button} from "antd";
import {FinancialAsset, Parity} from "reducers/types";
import {updateParityAction} from "reducers/asset";
import {DownloadOutlined} from "@ant-design/icons";
import useAPI from "hooks/API";

const {Column} = Table;

export const ParitiesPage = () => {
  const parities = useSelector(s => s.asset.parities);
  const api = useAPI();
  const assets = useSelector(s => s.asset.assets);
  const [search, setSearch] = useState<string>("");
  const [filterSource, setFilterSource] = useState<string>("all");
  const dispatch = useAppDispatch();

  const handleListeningChange = (id: string, isListening: boolean) => {
    dispatch(updateParityAction(id, isListening))
  }
  const parityIdsToAsset: {[k: string]: FinancialAsset} = {};
  assets.forEach(asset => {
    if (asset.parityId) {
      parityIdsToAsset[asset.parityId] = asset
    }
  });

  const renderListening = (p: Parity) => {
    if (p.source === "coinmarketcap") {
      return <Switch checked={true} />;
    }
    return <Switch defaultChecked={p.isListening} onChange={e => handleListeningChange(p.id, e)}/>;
  }

  const renderAsset = (p: Parity) => {
    if (parityIdsToAsset[p.id]) return <div>
      <span style={{marginRight: 20}}>
        <Link to={`/assets/${parityIdsToAsset[p.id].assetId}`}><Button type={"link"}>{parityIdsToAsset[p.id].category} / {parityIdsToAsset[p.id].displayName.en}</Button></Link>
      </span>
      {parityIdsToAsset[p.id].active ? <Tag color={"green"}>active</Tag> : <Tag color={"red"}>passive</Tag>}
    </div>;
    return null;
  }

  const getCount = (source: string) => parities.filter(i => i.source === source).length;

  const itemIdMatches = (item: Parity) => item.id.toLowerCase().indexOf(search.toLowerCase()) >= 0
  const itemAssetIdMatches = (item: Parity) => item.sourceAssetId.toLowerCase().indexOf(search.toLowerCase()) >= 0
  const itemNameMatches = (item: Parity) => {
    const dn = parityIdsToAsset[item.id] && parityIdsToAsset[item.id].displayName ? parityIdsToAsset[item.id].displayName.en || parityIdsToAsset[item.id].displayName.tr || "" : "";
    return (parityIdsToAsset[item.id] && dn.toLowerCase().indexOf(search.toLowerCase()) >= 0)
  }

  const filtered = parities
    .filter(item => !search || itemIdMatches(item) || itemAssetIdMatches(item) || itemNameMatches(item))
    .filter(item => filterSource === "all" || item.source === filterSource)

  const handleDownload = () => api.download('/admin/export/parities', 'parities.xls');

  return (
    <div style={{display: 'flex', justifyContent: 'center', flexDirection: "column"}}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h1>Parities</h1>
        <div>
          <span style={{marginRight: 20}}>
            <Select value={filterSource} onChange={setFilterSource} style={{width: 200}}>
              <Select.Option key={"all"} value={"all"}>All</Select.Option>
              <Select.Option key={"foreks"} value={"foreks"}>Foreks ({getCount("foreks")})</Select.Option>
              <Select.Option key={"coinmarketcap"} value={"coinmarketcap"}>CMC ({getCount("coinmarketcap")})</Select.Option>
              <Select.Option key={"polygon"} value={"polygon"}>Polygon ({getCount("polygon")})</Select.Option>
              <Select.Option key={"tefas"} value={"tefas"}>TEFAS ({getCount("tefas")})</Select.Option>
              <Select.Option key={"external"} value={"external"}>External ({getCount("external")})</Select.Option>
              <Select.Option key={"native"} value={"native"}>Native ({getCount("native")})</Select.Option>
              <Select.Option key={"placeholder"} value={"placeholder"}>Placeholder ({getCount("placeholder")})</Select.Option>
            </Select>
          </span>
          <Input placeholder={"Search"} style={{width: 200}} value={search} onChange={e => setSearch(e.target.value)}/>
          <Button danger onClick={handleDownload} icon={<DownloadOutlined />}>Download</Button>
        </div>
      </div>
      <Table dataSource={filtered} style={{marginTop: 20}} rowKey={r => r.id} size="small" bordered>
        <Column title="#" key="id" render={p => <Link to={`/parities/${p.id}`}>{p.id}</Link>}/>
        <Column title="Source" key="source" dataIndex="source"/>
        <Column title="Used by asset" key="usedByAsset" render={renderAsset}/>
        <Column title="Rate" key="usedByAsset" dataIndex={"rate"}/>
        <Column title="Listening from source?" key="isListening" render={renderListening}/>
      </Table>
    </div>
  );
}

export default ParitiesPage;
