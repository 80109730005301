import React, {ReactNode, useState} from 'react';
import {Button, Card, Spin, Table, Modal, Select} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useAppDispatch} from "store";
import AddResource from "./add";
import {setError, setSuccess} from "reducers/message";
import {MapOf, SourceChangeItem, SourceChanges} from "reducers/types";
import useAPI from "hooks/API";

const {Column} = Table;
const {confirm} = Modal;

enum FundActionTypes {
  FORINVEST_UPDATE,
  TEFAS_UPDATE,
  TEFAS_CHECK,
  CMC_CHECK,
  POLYGON_CHECK,
}

export const SourceChangesPage = () => {
  const [source, setSource] = useState<string>("foreks");
  const [fund,setFund] = useState<FundActionTypes>(FundActionTypes.FORINVEST_UPDATE)
  const [addResourceId, setAddResourceId] = useState<string>("");
  const [loadingChanges, setLoadingChanges] = useState<boolean>(false);
  const [updatingTefas, setUpdatingTefas] = useState<boolean>(false);
  const [updatingForeks, setUpdatingForeks] = useState<boolean>(false);
  const [checkingResource, setCheckingResource] = useState<boolean>(false);
  const [changes, setChanges] = useState<SourceChanges>();
  const dispatch = useAppDispatch();
  const api = useAPI();
  const addResourceMap: MapOf<MapOf<string>> = {};
  if (changes && changes.added) {
    changes.added.forEach(a => addResourceMap[a.id] = a.resourceData);
  }

  const handleTefasUpdate = () => {
    setUpdatingTefas(true);
    api.post(`/admin/resources/update/tefas`)
      .then(res => {
        const numRes = parseInt(res);
        if (numRes > 0) alert(`${res} values updated`);
        else alert(`No updates`);
      })
      .finally(() => {
        setUpdatingTefas(false);
      })
  }

  const handleForeksUpdate = () => {
    setUpdatingForeks(true);
    api.post(`/admin/parities/history/foreks`)
      .then(res => {
        if (res.success) alert(`${res.success} values updated`);
        else alert(`No updates`);
      })
      .finally(() => {
        setUpdatingForeks(false);
      })
  }

  const handleResourceCheckChanges = (sourceName: string) => {
    setCheckingResource(true);
    api.post(`/admin/resources/check/${sourceName}`)
      .then(res => {
        dispatch(setSuccess("Resource check completed"));
      })
      .finally(() => {
        setCheckingResource(false);
      })
  }

  const fundOptionAttributes = {
    [FundActionTypes.FORINVEST_UPDATE]:{handler:handleForeksUpdate,state:updatingForeks,label:"ForInvest Parities"},
    [FundActionTypes.TEFAS_UPDATE]:{handler:handleTefasUpdate,state:updatingTefas,label:"TEFAS Parities"},
    [FundActionTypes.TEFAS_CHECK]:{handler:() => handleResourceCheckChanges('tefas'),state:checkingResource,label:"TEFAS Assets"},
    [FundActionTypes.CMC_CHECK]:{handler:() => handleResourceCheckChanges('coinmarketcap'),state:checkingResource,label:"CoinMarketCap Assets"},
    [FundActionTypes.POLYGON_CHECK]:{handler:() => handleResourceCheckChanges('polygon'),state:checkingResource,label:"Polygon Assets"},
  }
  const handleFundAction = ()=>{
    if(fund!= undefined){
      fundOptionAttributes[fund]?.handler()
    }
  }

  const handleCheck = () => {
    setLoadingChanges(true);
    api.get(`/admin/source/check/${source}`)
      .then(res => {
        setLoadingChanges(false);
        setChanges(res);
      });
  }

  const handleIgnore = (resourceId: string) => {
    api.post(`/admin/assets/resource/${source}`, {resourceId})
      .then(() => {
        removeAssetFromPage(resourceId);
        dispatch(setSuccess("New asset ignored"));
      })
  }

  const handleRemove = (id: string, force: boolean) => {
    const url = force ? `/admin/assets/resource/${source}/${id}?deleteWithAsset=true` : `/admin/assets/resource/${source}/${id}`;
    api.delete(url)
      .then(res => {
        if (res.success) {
          removeAssetFromPage(id);
          dispatch(setSuccess("Removed from Paramla"));
        } else if (res.code === "not-found") {
          dispatch(setError("Not found in DB"));
        } else if (res.code === "asset-used") {
          dispatch(setError(`Cannot delete: ${res.message}`));
        } else if (res.code === "asset-exists") {
          confirm({
            title: 'Delete with assets?',
            icon: <ExclamationCircleOutlined/>,
            content: <span>{res.message}
              <br/><br/>Do you want to delete these assets too?
            </span>,
            onOk() {
              handleRemove(id, true);
            },
          });
        } else {
          dispatch(setError("Cannot remove Source"));
        }
      })
  }

  const handleAdd = (id: string) => {
    setAddResourceId(id);
  }

  const afterAdd = (message: string|ReactNode) => {
    setAddResourceId("");
    removeAssetFromPage(addResourceId);
    dispatch(setSuccess(message));
  }

  const removeAssetFromPage = (id: string) => {
    const added = changes!.added.filter(c => c.id !== id);
    const removed = changes!.removed.filter(c => c.id !== id);
    setChanges({added, removed});
  }
  const handleAddConfirm = (data: any) => {
    data.source = source;
    data.sourceId = addResourceId;

    api.post(`/admin/assets`, data)
      .then(res => {
        if (res.success) {
          afterAdd(<span>New asset and parity saved. Asset: {res.code}</span>);
        }
      })
  }

  const handleExistingConfirm = (sourceAssetId: string, targetAssetId: string) => {
    const data = {
      source,
      sourceId: addResourceId,
      sourceAssetId,
      targetAssetId,
      isListening: true,
    };
    api.post(`/admin/parities`, data)
      .then(() => afterAdd("New parity saved"))
  }

  const renderItems = (items: SourceChangeItem[], isRemoved: boolean) => {
    if (!items || items.length === 0) {
      return null;
    }
    return <Table dataSource={items} pagination={false} rowKey={r => r.id}>
      <Column title="ID" key="id" dataIndex="id"/>
      <Column title="Category" key="category" dataIndex="category"/>
      <Column title="Name" key="name" dataIndex="name"/>
      {isRemoved &&
      <Column key="action_remove" render={a => <Button size="small" danger onClick={() => handleRemove(a.id, false)}>Remove from Paramla</Button>}/>}
      {!isRemoved && <Column key="action_add" render={a => <Button size="small" type="primary" onClick={() => handleAdd(a.id)}>Add to Paramla</Button>}/>}
      {!isRemoved && <Column key="action_ignore" render={a => <Button size="small" danger onClick={() => handleIgnore(a.id)}>Ignore</Button>}/>}
    </Table>
  }

  return (
    <div>
      <div>
        <Select value={fund} onChange={setFund} style={{width: 200}}>
          {/* {Object.entries(fundOptionAttributes).map(([type,{state,label}])=>
            <Select.Option key={type} disabled={state} value={type}>{label} {state && <Spin/>}</Select.Option>
          )} */}
              <Select.Option
                disabled={fundOptionAttributes[FundActionTypes.FORINVEST_UPDATE].state}
                value={FundActionTypes.FORINVEST_UPDATE}
              >
                {fundOptionAttributes[FundActionTypes.FORINVEST_UPDATE].label}{" "}
                {fundOptionAttributes[FundActionTypes.FORINVEST_UPDATE].state && <Spin />}
              </Select.Option>
              <Select.Option
                disabled={fundOptionAttributes[FundActionTypes.TEFAS_UPDATE].state}
                value={FundActionTypes.TEFAS_UPDATE}
              >
                {fundOptionAttributes[FundActionTypes.TEFAS_UPDATE].label}
                {fundOptionAttributes[FundActionTypes.TEFAS_UPDATE].state && <Spin />}
              </Select.Option>
              <Select.Option
                disabled={fundOptionAttributes[FundActionTypes.TEFAS_CHECK].state}
                value={FundActionTypes.TEFAS_CHECK}
              >
                {fundOptionAttributes[FundActionTypes.TEFAS_CHECK].label}
                {fundOptionAttributes[FundActionTypes.TEFAS_CHECK].state && <Spin />}
              </Select.Option>
              <Select.Option
                disabled={fundOptionAttributes[FundActionTypes.CMC_CHECK].state}
                value={FundActionTypes.CMC_CHECK}
              >
                {fundOptionAttributes[FundActionTypes.CMC_CHECK].label}
                {fundOptionAttributes[FundActionTypes.CMC_CHECK].state && <Spin />}
              </Select.Option>
              <Select.Option
                disabled={fundOptionAttributes[FundActionTypes.POLYGON_CHECK].state}
                value={FundActionTypes.POLYGON_CHECK}
              >
                {fundOptionAttributes[FundActionTypes.POLYGON_CHECK].label}
                {fundOptionAttributes[FundActionTypes.POLYGON_CHECK].state && <Spin />}
              </Select.Option>
          </Select>
        <Button disabled={fundOptionAttributes[fund].state} onClick={handleFundAction}>Update</Button>
      </div>
      <br/>
      <div>
        <Select value={source} onChange={setSource} style={{width: 200}}>
          <Select.Option value="foreks">ForInvest</Select.Option>
          <Select.Option value="coinmarketcap">CMC</Select.Option>
          <Select.Option value="polygon">Polygon</Select.Option>
          <Select.Option value="tefas">TEFAS</Select.Option>
        </Select>
        <Button onClick={handleCheck}>Check</Button>
      </div>
      <Spin spinning={loadingChanges}>
        {changes &&
        <div style={{display: "flex", alignItems: "flex-start", justifyContent: "space-around", marginTop: 50}}>
          <Card bordered title={`Added`} style={{width: "40vw"}}>
            {renderItems(changes.added, false)}
          </Card>
          <Card bordered title={`Removed`} style={{width: "40vw"}}>
            {renderItems(changes.removed, true)}
          </Card>
        </div>
        }
      </Spin>
      {
        addResourceId !== "" &&
        <Modal width={1000} visible={true} onCancel={() => setAddResourceId("")} footer={null}>
          <AddResource source={source} handleAdd={handleAddConfirm} handleExisting={handleExistingConfirm} resource={addResourceId !== "" ? addResourceMap[addResourceId]: {}}/>
        </Modal>
      }
    </div>
  );
}

export default SourceChangesPage;
