export const ASSET_ID_USD = "p:a:currency:usd";
export const ASSET_ID_TRY = "p:a:tr-lira:tl";

export const availableTargetCurrencies = [
  {
    value: ASSET_ID_TRY,
    label: "TL"
  },
  {
    value: ASSET_ID_USD,
    label: "USD"
  },
];