import React from 'react';

export const Page = () => {
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <h1>Paramla</h1>
    </div>
  );
}

export default Page;