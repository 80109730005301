import React, {useState} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {useSelector} from "store";
import {Badge, Button, Input, Select, Switch, Table} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import useAPI from "hooks/API";
import {FinancialAsset, Parity} from "reducers/types";

const {Column} = Table;

interface Filters {
  category: string,
  listingStatus: string
}

export const AssetsPage = () => {
  const api = useAPI();
  const [filters, setFilters] = useState<Filters>({category: "all", listingStatus: "all"});
  const assets = useSelector(s => s.asset.assets);
  const parities = useSelector(s => s.asset.parities);
  const getStatus = useSelector(s => s.asset.assetsGetStatus);
  const [search, setSearch] = useState<string>("");

  const paritiesById: {[k: string]: Parity} = {};
  parities?.forEach(p => paritiesById[p.id] = p)

  const categories = [
    {"id": "all", "name": "All"},
    {"id": "GOLD_SILVER", "name": "Gold / Silver / Commodity"},
    {"id": "CURRENCY", "name": "Foreign Currency"},
    {"id": "FUND", "name": "Fund"},
    {"id": "EUROBOND", "name": "Eurobond"},
    {"id": "STOCK_IMKB", "name": "BIST"},
    {"id": "STOCK_NASDAQ", "name": "US Exchanges"},
    {"id": "CRYPTO", "name": "Crypto Currency"}
  ]

  const handleActiveChange = (id: string, isActive: boolean) => {
    api.post(`/admin/assets/${id}`, {isActive})
  }

  const renderActive = (p: FinancialAsset) => {
    return <Switch defaultChecked={p.active} onChange={e => handleActiveChange(p.assetId, e)}/>;
  }

  const renderValue = (p: Parity) => {
    return <div style={{textAlign: "right"}}>
      <div>{p.rate}</div>
      <div style={{fontSize: 8}}>{p.lastUpdatedAt ? moment.unix(parseInt(p.lastUpdatedAt) / 1000).fromNow() : ""}</div>
    </div>
  }

  const getCount = (cid: string) => assets.filter(a => (cid === "all" && a.category !== "TR_LIRA") || a.category === cid).length;

  const filterListingStatus = (a: FinancialAsset) => {
    if (filters.listingStatus === "all") return true;
    if (filters.listingStatus === "listed" && a.active) return true;
    if (filters.listingStatus === "notlisted" && !a.active) return true;
    if (filters.listingStatus === "noparity" && !paritiesById[a.parityId]) return true;
    return false;
  }

  const data = assets
    .filter(a => filters.category === "all" || a.category === filters.category)
    .filter(filterListingStatus)
    .filter(a => {
      if (!search) return true;
      if (a.id.indexOf(search.toLowerCase()) >= 0) return true;
      const dn = a.displayName.en || a.displayName.tr || "";
      return dn.toLowerCase().indexOf(search.toLowerCase()) >= 0
    });

  const handleDownload = () => api.download('/admin/export/assets', 'assets.xls');

  return (
    <div className={"div-column"}>
      <div className={"div-row-spaced"}>
        <div className={"div-row-spaced"}>
          <h1>Assets</h1>
          <Link to={"/assets/new"}><Button type={"primary"} style={{marginLeft: 30}}>Add</Button></Link>
        </div>
        <div>
          <Select style={{width: 240}} value={filters.category} onChange={category => setFilters({...filters, category})}>
            {categories.map(c => <Select.Option value={c.id} key={c.id}>{c.name} ({getCount(c.id)})</Select.Option>)}
          </Select>
          <Select style={{width: 200}} value={filters.listingStatus} onChange={listingStatus => setFilters({...filters, listingStatus})}>
            <Select.Option value="all" key="all">All</Select.Option>
            <Select.Option value="listed" key="listed">Listed</Select.Option>
            <Select.Option value="notlisted" key="notlisted">Not listed</Select.Option>
            <Select.Option value="noparity" key="noparity">No Parity</Select.Option>
          </Select>
          <Input style={{width: 200}} value={search} onChange={e => setSearch(e.target.value)} placeholder={"Search.."}/>
          <Button danger onClick={handleDownload} icon={<DownloadOutlined />}>Download</Button>
        </div>
      </div>
      <Table dataSource={data} style={{marginTop: 20}} loading={getStatus === "loading"} rowKey={r => r.id} size="small" bordered>
        <Column title="ID" key="id" render={(a) => <Link to={`/assets/${a['assetId']}`}>{a['assetId']}</Link>}/>
        <Column title="ABBR" key="abbrEN" dataIndex="abbreviation" render={v => v.en}/>
        <Column title="DESC" key="descEN" dataIndex="displayName" render={v => v.en}/>
        <Column title="UNIT" key="unitEN" dataIndex="unit" render={v => v.en}/>
        <Column title="PARITY" key="parityId" render={a => <Link to={`/parities/${a.parityId}`}><Badge color={paritiesById[a.parityId].isListening ? "green" : "red"}>{paritiesById[a.parityId].id}</Badge></Link>} />
        <Column title="PARITY VALUE" key="parityValue" render={a => renderValue(paritiesById[a.parityId])} />
        <Column title="IS LISTED ON APP?" key="active" render={renderActive}/>
      </Table>
    </div>
  );
}

export default AssetsPage;
