import { Button, Select, Table } from "antd";
import TextArea from "antd/lib/input/TextArea";
import useAPI from "hooks/API";
import * as React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useSelector } from "store";
import "./Portfolio.less";
import { setError } from "../../../../reducers/message";
import useContentSearch from "hooks/useContentSearch";

const columns = [
  {
    dataIndex: "portfolioId",
    title: "Portfolio",
    render: (portfolioId: any, { portfolioName }: any) => {
      return <Link to={`/portfolios/${portfolioId}`}>{portfolioName}</Link>;
    },
  },
];
const expandableTableColumns = [
  { dataIndex: "assetId", title: "Asset" },
  { dataIndex: "amount", title: "Amount" },
];

export default function Portfolio() {
  const dispatch = useAppDispatch();
  const [{ name, ...filters }, setFilters] = React.useState<any>({});
  const [isSearching, setSearching] = React.useState<boolean>(false);
  const api = useAPI();
  const [data, setData] = React.useState<any>([]);
  const { setText, result } = useContentSearch({
    searchKeys: ["portfolioName"],
    payload: data,
  });
  const onSearch = () => {
    const filterValues = Object.values({ name, ...filters }).filter((val)=>val);
    if (filterValues.length === 0) {
      dispatch(setError("Please add filters first"));
    } else {
      setSearching(true);
      api.post(`/admin/portfolios/filters`, filterValues).then(({ result }) => {
        setData(result);
        setSearching(false);
      });
    }
  };
  const getAmountForFilter = (filter: any, amounts: any) =>
    amounts.find((el: any) => el.assetId === filter.assetId).amount;
  return (
    <div className="find-portfolio-page-container">
      <PortfolioFilter
        filters={filters}
        setFilters={setFilters}
        onSearch={onSearch}
      />
      <TextArea
        onChange={(event) => {
          setText(event.target.value);
        }}
        autoSize
        placeholder="Search"
      ></TextArea>
      <Table
        loading={isSearching}
        columns={[
          ...columns,
          ...(Object.values(filters)
            .slice(0, 5)
            .map((filter: any) => ({
              filterSearch: true,
              dataIndex: "assetAmounts",
              title: filter.label,
              sorter: (a: any, b: any) =>
                getAmountForFilter(filter, a.assetAmounts) -
                getAmountForFilter(filter, b.assetAmounts),
              render: (value: any) => {
                return getAmountForFilter(filter, value);
              },
            })) as any),
        ]}
        expandable={{
          expandedRowRender: ({ assetAmounts }) => (
            <div className="expanded-row">
              <Table
                columns={expandableTableColumns}
                rowKey={"assetId"}
                dataSource={assetAmounts}
              />
            </div>
          ),
        }}
        rowKey="portfolioId"
        dataSource={result}
      />
    </div>
  );
}

function PortfolioFilter({ filters, setFilters, onSearch }: any) {
  const [form, setForm] = React.useState<any>({});
  const assets = useSelector((s) => s.asset.assets);
  const filterOption: any = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  console.log(form);
  return (
    <div className="portfolio-filter-container flex">
      <div className="flex filter-form">
        <Select
          onChange={(value, option: any) => {
            setForm({ assetId: value, label: option?.label });
          }}
          showSearch
          placeholder="Select an asset"
          value={form?.assetId}
          filterOption={filterOption}
          options={assets.map(({ assetId, displayName }) => ({
            value: assetId,
            label: `${displayName.en} (${assetId})`,
          }))}
        />
        <TextArea
          value={form?.minAmount}
          disabled={form?.exactAmount}
          onChange={(event) =>
            setForm((prevState: any) => ({
              ...prevState,
              minAmount: event.target.value,
            }))
          }
          autoSize
          placeholder="min"
        ></TextArea>
        <TextArea
          value={form?.maxAmount}
          disabled={form?.exactAmount}
          onChange={(event) =>
            setForm((prevState: any) => ({
              ...prevState,
              maxAmount: event.target.value,
            }))
          }
          autoSize
          placeholder="max"
        ></TextArea>
        <TextArea
          value={form?.exactAmount}
          onChange={(event) =>
            setForm((prevState: any) => {
              delete prevState.maxAmount;
              delete prevState.minAmount;
              return {
                ...prevState,
                exactAmount: event.target.value,
              };
            })
          }
          autoSize
          placeholder="exact"
        ></TextArea>
        <Button
          disabled={
            !(
              form?.assetId &&
              (form?.minAmount || form?.maxAmount || form?.exactAmount)
            )
          }
          onClick={() => {
            setFilters((prevState: any) => ({
              ...prevState,
              [form?.assetId]: form,
            }));
          }}
          type="primary"
        >
          Add
        </Button>
        <TextArea
          value={form?.name}
          onChange={(event) =>
            setFilters((prevState: any) => {
              return {
                ...prevState,
                name: { name: event.target.value },
              };
            })
          }
          autoSize
          placeholder="name"
        ></TextArea>
        <Button
          onClick={() => {
            onSearch();
          }}
          type="primary"
        >
          Search
        </Button>
      </div>
      <div className="flex saved-filters">
        {Object.values(filters).map(
          ({ assetId, label, minAmount, maxAmount, exactAmount }: any) => (
            <div
              className="flex filter-card"
              onClick={() =>
                setForm({ assetId, minAmount, maxAmount, exactAmount })
              }
            >
              <div className="label-container">
                <div className="label">{label}</div>
                <div>
                  min : {minAmount}
                  <br />
                  max : {maxAmount}
                  <br />
                  exact : {exactAmount}
                </div>
              </div>
              <div
                onClick={(event) => {
                  setFilters((prevState: any) => {
                    delete prevState[assetId];
                    return { ...prevState };
                  });
                  event.stopPropagation();
                }}
                className="delete-icon"
              >
                X
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
