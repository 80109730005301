import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "../store";
import APIService from "services/ApiService";
import {ProgressState, User} from "./types";

export interface UsersState {
  usersGetStatus: ProgressState
  users: User[]
}

const initialState: UsersState = {
  usersGetStatus: "idle",
  users: [],
}

const reducer = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getAllUsersInit(state) {
      state.usersGetStatus = "loading"
    },
    getAllUsersSuccess(state, action: PayloadAction<User[]>) {
      state.users = action.payload;
      state.usersGetStatus = "succeeded"
    },
    getAllUsersError(state) {
      state.usersGetStatus = "failed"
    },
  }
});

const {
  getAllUsersError,
  getAllUsersInit,
  getAllUsersSuccess,
} = reducer.actions;

export interface UserSearchParams {
  id?: string
  role?: string
  skip: number
  limit: number
}

export const getAllUsersAction = (params: UserSearchParams) => (dispatch: AppDispatch) => {
  dispatch(getAllUsersInit());
  const api = new APIService();
  api.get(`/admin/users`, {id: params.id, role: params.role, skip: params.skip, limit: params.limit})
    .then(res => {
      dispatch(getAllUsersSuccess(res.result))
    })
    .catch(() => dispatch(getAllUsersError()))
}
export default reducer;
