import {Menu} from "antd";
import {Link} from "react-router-dom";
import {PoweroffOutlined} from "@ant-design/icons";
import React from "react";
import {Layout} from "antd";
import {AuthState} from "../../reducers/auth";
import {useSelector} from "../../store";
// import {isStaging, updateSessionConfig} from "services/SessionService";

const {Header} = Layout;

const AppHeader = () => {
  const auth: AuthState = useSelector(state => state.auth);
  // const [isTestEnv, setTestEnv] = useState(isStaging());

  const adminMenu = [
    {id: "assets", url: "/assets", label: "Assets", lines: 1},
    {id: "parities", url: "/parities", label: "Parities", lines: 1},
    {id: "changes", url: "/source/changes", label: "Sources", lines: 1},
    {id: "report_devices", url: "/report/devices", label: "Reports"},
    {id: "find", url: "/find", label: "Find"},
    // {id: "report_subscriptions", url: "/report/subscriptions", label: "Subscriptions" },
    {id: "report_user_messages", url: "/report/messages", label: "Messages", lines: 1},
    {id: "configs", url: "/configs", label: "Config", lines: 1},
  ];

  const renderMenuItem = (m: any) => {
    if (m.lines !== 2) {
      return <Menu.Item key={m.id}><Link to={m.url}>{m.label}</Link></Menu.Item>
    }

    return <Menu.Item key={m.id}><Link to={m.url}>
      <div style={{textAlign: "center", height: 64, lineHeight: "24px", paddingTop: 4}} dangerouslySetInnerHTML={{__html: m.label.split(" ").join("<br />")}}/>
    </Link></Menu.Item>
  }

  // const handleTestEnvChange = (checked: boolean) => {
  //   updateSessionConfig({isStaging: checked});
  //   setTestEnv(checked);
  // }

  return <Header>
    <div className={process.env.NODE_ENV === 'production' ? 'layout-logo' : 'layout-logo layout-logo-dev'}>
      Paramla
    </div>
    {/*{*/}
    {/*  isStaging && <div className={"layout-logo-test"}/>*/}
    {/*}*/}
    <Menu theme="dark" mode="horizontal">
      {
        adminMenu.map(m => renderMenuItem(m))
      }
      {/*<Menu.Item className="layout-menu-switch" key="istest">*/}
      {/*  Use Test Env: <Switch checked={isTestEnv} onChange={handleTestEnvChange}/>*/}
      {/*</Menu.Item>*/}
      {
        auth.isAuth ?
          <Menu.Item className="layout-menu-right" icon={auth.isAuth ? null : <PoweroffOutlined/>} key="logout">
            <Link to="/auth/logout">Logout</Link>
          </Menu.Item> :
          <Menu.Item className="layout-menu-right" key="logout">
          </Menu.Item>
      }
    </Menu>
  </Header>
}

export default AppHeader;
